import React from "react";
import "../styles/FooterSticky.css";
import LoginLogoutMenu from "./LoginLogoutMenu";


const FooterSticky = ({ profile, logIn, logOut }) => {
    return (
        <div className="footerSticky">
            <LoginLogoutMenu profile={profile} logIn={logIn} logOut={logOut} footer={true} />
        </div>
    );
}

export default FooterSticky;
