import { Box } from "@mui/material";
import useUpdateGameData from "games/helpers/useUpdateGameData";



const WinnerCard = ({ data = {}, datas = [], extras = [] }) => {
    const { getMinuteSecondString } = useUpdateGameData();
    return (
        <>
            <Box><img src={data['user']['avatar']} alt="user" style={{ maxWidth: '80px' }}/></Box>
            <Box>{data['user']['given_name']}</Box>
            <Box>Score: {data['game']['score']}</Box>
            <Box>Time: {getMinuteSecondString(data['game']['time'])}</Box>
            {datas.map((item, key) => {
                if (data['game']['data'][item.field]) {
                    return <Box key={key}>{item.title}: {data['game']['data'][item.field]}</Box>
                }
                return null;
            })}
            {extras.map((item, key) => {
                return <Box key={key}>{item.title}: {item.value}</Box>
            })}
        </>
    );

}

export default WinnerCard;