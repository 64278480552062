import { useState } from "react";
import { Box, Button, Divider, Link, Menu, MenuItem, Tooltip, Typography } from "@mui/material";

import ShareIcon from '@mui/icons-material/Share';

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import RedditIcon from "@mui/icons-material/Reddit";
import PinterestIcon from "@mui/icons-material/Pinterest";
import XIcon from '@mui/icons-material/X';
import FacebookIcon from "@mui/icons-material/Facebook";
import { ContentCopy } from "@mui/icons-material";


const BUTTON_SX = {
    padding: '0px 6px',
    margin: '0 2px -2px',
    ':hover': {
        color: '#36F !important'
    },
};

const ShareContent = ({ url = '', title = '', sx = {}, iconSx={}, buttonSx={}, tooltip = '', tooltipPorps = { placement: 'top', arrow: true }}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleShareClick = async (type) => {
        if (type === 'copy') {
            await navigator.clipboard.writeText(url);
        }

        handleClose();
        return true;
    }

    return (
        <Tooltip title={tooltip}
        // @ts-ignore
        placement={tooltipPorps.placement} arrow={tooltipPorps.arrow}>
            <Box className="shareButtons" sx={sx}>
                <Button
                    id="share-menu-button"
                    aria-controls={open ? 'share-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={buttonSx}
                >
                    <ShareIcon sx={iconSx}/>
                </Button>
                <Menu
                    id="login-logout-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'login-logout-menu-button',
                    }}
                >
                    <Typography sx={{ padding: '3px 15px 8px', margin: '0 0' }}>{title}</Typography>
                    <Divider />
                    <MenuItem onClick={() => {  }}
                        sx={{
                            padding: '15px 5px 8px',
                            margin: 0,
                            cursor: 'default',
                            "&:hover": { backgroundColor: "transparent !important" }
                        }}
                    >
                        <Tooltip title="Copy Link" placement="top" arrow={true}>
                            <Link
                                onClick={() => { handleShareClick('copy'); }}
                                sx={{ ...BUTTON_SX, cursor: 'pointer' }}
                            >
                                <ContentCopy fontSize="small" />
                            </Link>
                        </Tooltip>
                        <Divider orientation="vertical" variant="fullWidth" flexItem />
                        <Tooltip title="Facebook" placement="top" arrow={true}>
                            <Link
                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`} target="_blank"
                                onClick={() => { handleShareClick('fb'); }}
                                sx={BUTTON_SX}
                            >
                                <FacebookIcon fontSize="small" />
                            </Link>
                        </Tooltip>
                        <Divider orientation="vertical" variant="fullWidth" flexItem />
                        <Tooltip title="Twitter" placement="top" arrow={true}>
                            <Link
                                href={`https://x.com/intent/tweet?url=${encodeURIComponent(url)}`} target="_blank"
                                onClick={() => { handleShareClick('x'); }}
                                sx={BUTTON_SX}
                            >
                                <XIcon fontSize="small" />
                            </Link>
                        </Tooltip>
                        <Divider orientation="vertical" variant="fullWidth" flexItem />
                        <Tooltip title="X" placement="top" arrow={true}>
                            <Link
                                href={`https://web.whatsapp.com://send?text=${encodeURIComponent(url)}`} target="_blank"
                                onClick={() => { handleShareClick('x'); }}
                                sx={BUTTON_SX}
                            >
                                <WhatsAppIcon fontSize="small" />
                            </Link>
                        </Tooltip>
                        <Divider orientation="vertical" variant="fullWidth" flexItem />
                        <Tooltip title="Pinterest" placement="top" arrow={true}>
                            <Link
                                href={`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}`} target="_blank"
                                onClick={() => { handleShareClick('x'); }}
                                sx={BUTTON_SX}
                            >
                                <PinterestIcon fontSize="small" />
                            </Link>
                        </Tooltip>
                        <Divider orientation="vertical" variant="fullWidth" flexItem />
                        <Tooltip title="Messenger" placement="top" arrow={true}>
                            <Link
                                href={`fb-messenger://share/?link=${encodeURIComponent(url)}`} target="_blank"
                                onClick={() => { handleShareClick('x'); }}
                                sx={BUTTON_SX}
                            >
                                <FacebookIcon fontSize="small" />
                            </Link>
                        </Tooltip>
                        <Divider orientation="vertical" variant="fullWidth" flexItem />
                        <Tooltip title="LinkedIn" placement="top" arrow={true}>
                            <Link
                                href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`} target="_blank"
                                onClick={() => { handleShareClick('x'); }}
                                sx={BUTTON_SX}
                            >
                                <LinkedInIcon fontSize="small" />
                            </Link>
                        </Tooltip>
                        <Divider orientation="vertical" variant="fullWidth" flexItem />
                        <Tooltip title="Reddit" placement="top" arrow={true}>
                            <Link
                                href={`https://www.reddit.com/submit?url=${encodeURIComponent(url)}`} target="_blank"
                                onClick={() => { handleShareClick('x'); }}
                                sx={BUTTON_SX}
                            >
                                <RedditIcon fontSize="small" />
                            </Link>
                        </Tooltip>
                    </MenuItem>
                </Menu>
            </Box>
        </Tooltip>
    );
}


export default ShareContent;