import { useEffect, useState } from "react";

const useFetchData = ({
        url,
        timeout = 30,
        method = 'GET',
        type = 'json',
        dataSuccessVariable = 'success', // For Type JSON
        dataVariable = 'data', // For Type JSON
        body = null
    }) => {
    const [data, setData] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let unmounted = false;
        const abortController = new AbortController();

        (async () => {
            try {
                setLoading(true);
                setErrorMessage('');
                const response = await fetch(url,
                    {
                        method: method,
                        headers: {
                            'Content-Type' : 'application/json',
                            mode:'cors'
                        },
                        body,
                        signal: abortController.signal
                    }
                );

                setLoading(false);

                if (!unmounted) {
                    if (response.status === 200) {
                        if (type === 'json') {
                            const data = await response.json();
                            if (!unmounted) {
                                setResponseData(data);

                                if (dataSuccessVariable) {
                                    if (!data[dataSuccessVariable]) {
                                        throw new Error(`${data.message}`);
                                    }
                                }

                                if (dataVariable) {
                                    setData(data[dataVariable]);
                                } else {
                                    setData(data);
                                }
                                unmounted = true;
                            }
                        } else {
                            const content = await response.text();
                            setData(content);
                            unmounted = true;
                        }
                    } else {
                        if (!unmounted) {
                            throw new Error(`${response.status}`);
                        }
                    }
                }
            } catch(error) {
                if (!unmounted) {
                    unmounted = true;
                    setError(true);
                    setErrorMessage(`${error}`);
                    setLoading(false);
                }
            }
        })();

        return () => {
            unmounted = true;
            abortController.abort();
        }

    }, [url, timeout, type, method, dataSuccessVariable, dataVariable, body]);

    return { data, loading, error, errorMessage, responseData };
};

export default useFetchData;