import { config } from "Constants";


const useUpdateGameData = () => {
    const createNewGameBoard = async ({ gameid, token = '', data = {} }) => {
        const url = config.url.API_URL + 'game/' + gameid + '/create';
        const abortController = new AbortController();

        try {
            const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type' : 'application/json',
                        mode: 'cors'
                    },
                    body: JSON.stringify({
                        token,
                        ...data
                    }),
                    signal: abortController.signal
                }
            );

            if (response.status === 200) {
                const data = await response.json();
                return data;
            } else {
                return null;
            }
        // eslint-disable-next-line no-unused-vars
        } catch(_) {
            return null;
        }
    };

    const joinNewGameBoard = async ({ gameid, token = '', data = {} }) => {
        const url = config.url.API_URL + 'game/' + gameid + '/create';
        const abortController = new AbortController();

        try {
            const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type' : 'application/json',
                        mode: 'cors'
                    },
                    body: JSON.stringify({
                        token,
                        ...data
                    }),
                    signal: abortController.signal
                }
            );

            if (response.status === 200) {
                const data = await response.json();
                return data;
            } else {
                return null;
            }
        // eslint-disable-next-line no-unused-vars
        } catch(_) {
            return null;
        }
    };

    const updateGameData = async ({ gameid, token = '', hash = null, data = {} }) => {
        const url = config.url.API_URL + 'game/' + (hash ? hash + '/share' : gameid + '/win');
        const abortController = new AbortController();

        try {
            const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type' : 'application/json',
                        mode: 'cors'
                    },
                    body: JSON.stringify({
                        token,
                        ...data
                    }),
                    signal: abortController.signal
                }
            );

            if (response.status === 200) {
                const data = await response.json();
                return data;
            } else {
                return null;
            }
        // eslint-disable-next-line no-unused-vars
        } catch(_) {
            return null;
        }
    };

    const getGameData = async ({ gameid, hash = '', token = null }) => {
        const url = config.url.API_URL + 'game/' + gameid + '/share/' + hash;
        const abortController = new AbortController();

        try {
            const response = await fetch(url, {
                    method: token ? 'POST' : 'GET',
                    headers: {
                        'Content-Type' : 'application/json',
                        mode: 'cors'
                    },
                    body: token ? JSON.stringify({ token }) : null,
                    signal: abortController.signal
                }
            );

            if (response.status === 200) {
                const data = await response.json();
                return data;
            } else {
                return null;
            }
        // eslint-disable-next-line no-unused-vars
        } catch(_) {
            return null;
        }
    };

    const getMinuteSecondString = (time) => {
        const elapsedMinutes = Math.floor(time / 60000);
        const elapsedSeconds = Math.floor(time / 1000) - elapsedMinutes * 60;
        return `${elapsedMinutes < 10 ? '0' : ''}${elapsedMinutes}:${elapsedSeconds < 10 ? '0' : ''}${elapsedSeconds.toFixed(0)}`;
    }

    return {
        createNewGameBoard,
        joinNewGameBoard,
        getGameData,
        updateGameData,
        getMinuteSecondString
    };
};

export default useUpdateGameData;