import { Link, useParams } from "react-router-dom";
import GitHubIcon from "@mui/icons-material/GitHub";
import PreviewIcon from '@mui/icons-material/Preview';
import LinkIcon from '@mui/icons-material/Link';
import "../styles/GameDisplay.css";
import { config } from '../Constants'

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import Moment from 'moment';
import { Box, Tooltip, Typography } from "@mui/material";
// import { Tooltip } from 'react-tooltip';
import useFetchData from "helpers/useFetchData";
import DisplayProgressCircles from "components/DisplayProgressCircles";
import React, { useState } from "react";
import ShareContent from "components/ShareContent";
import usePageSEO from "hooks/usePageSEO";

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '400px'
}


const GameDisplay = ({ profile = null, logIn = () => {}, token = null}) => {
	const [ loaded, setLoaded ] = useState(null);
	const [importedGame, setImportedGame] = useState(null);
	const { src, hash } = useParams();
	const url = config.url.API_URL + 'game/' + src;
	const { data: game, loading, error, errorMessage, responseData } = useFetchData({ url });

	usePageSEO({
		title: `RepulsiveCoder - Game - ${game?.title}`,
		description: `RepulsiveCoder - Game - ${game?.description?.replace(/<[^>]*>?/gm, '').slice(0, 250)}`,
		ogImage: game?.images??[`${window.location.origin}/media/RepulsiveCoder_FB.jpg`],
		ogUrl: window.location.href,
		ogType: 'portfolio'
	});

	if (game && game.category === 'React' && game.url) {
		if (loaded !== game.url) {
			const importComponent = async () => {
				const module = await import(`games/${game.url}/${game.url}`);
				const AnotherGame = module.default;
				setImportedGame(<AnotherGame gameid={game.uri} game={game} profile={profile} logIn={logIn} token={token} hash={hash} />);
			};

			importComponent();
			setLoaded(game.url);
		}
	}


	return (
	<>
		{loading && (
		<div className="game">
			<h1>Game Details</h1>
			{(loading || error) && (
			<Box sx={{ display: 'grid', placeItems: 'center', height: 'calc(100vh - 550px)'}}>
				{!error && (<DisplayProgressCircles />)}
				{error && (<div className="errorMsg">{errorMessage}</div>)}
			</Box>
			)}
		</div>
		)}
		{(!loading && !!game) &&
		(
		<div className="game">
			{/* <meta property="og:title" content={`RepulsiveCoder - Game - ${game.title}`} />
			<meta property="og:description" content={`RepulsiveCoder - Game - ${game.description.slice(0, 100)}`} />
			{game.images.length > 0 && game.images.map((ogImage, index) => <meta key={index} property="og:image" content={`/media/game/${ogImage}`} />)} */}
			{game?.category === 'React' ? <>{importedGame}</>  : (<>
			{game?.images?.length > 1 && (
				<div className="slide-container">
					<Slide>
					{game.images.map((slideImage, index)=> (
						<div key={index}>
							<div style={{ ...divStyle, 'backgroundImage': `url(/media/game/${slideImage})` }} />
						</div>
					))}
					</Slide>
				</div>
			)}

			{game?.images?.length === 1 && (<div style={{ width: '800px', ...divStyle, 'backgroundImage': `url(/media/game/${game.images[0]})` }} />)}
			<Box component="fieldset" className="descriptionContainerFieldset" sx={{ animationDuration: '2s !important' }}>
				<Typography component="legend" >Description </Typography>
				<div className="description" dangerouslySetInnerHTML={{ __html: game.description }} />

				<div className="technology">
					<b>Technology:</b> {game.technology}
				</div>

				<div className="description">
					<b>Published:</b> {game.displayonlyyear === '1' ? Moment(game.published).format('Y') : game.hidedate === '1' ? Moment(game.published).format('MMMM Y') : Moment(game.published).format('MMMM D, Y')}
				</div>

				{!!game.dates && game.dates.length > 0 && (
					<div className="description">
					<b>Versions:</b>
					<div className="versions">
					{game.dates.map((dt, dk) => {
					return (<div key={dk}>{dt.version}, <b>Published:</b> {game.displayonlyyear === '1' ? Moment(dt.date).format('Y') : game.hidedate === '1' ? Moment(dt.date).format('MMMM Y') : Moment(dt.date).format('MMMM D, Y')}</div>);
					})}
					</div>
					</div>
				)}

				{(!!game.demo || !!game.url || !!game.github) && (
					<div className="external-links">
						{!!game.demo && (
							<a href={`${game.demo}`} target="_blank" className="button" rel="noreferrer"><PreviewIcon /> Demo</a>
						) }

						{!!game.url && (
							<a href={`${game.url}`} target="_blank" className="button" rel="noreferrer"><LinkIcon /> Website</a>
						) }

						{!!game.github && (
							<a href={`${game.github}`} target="_blank" className="button" rel="noreferrer"><GitHubIcon /> Github</a>
						) }
					</div>
				) }

			</Box>
			</>)}
			<Box className="buttonContainer" sx={{ position: 'relative' }}>
				<span className="prev">
					{responseData.prev && (
						<Tooltip
							title={
								<Box className="tooltipContent">
									<h2>{responseData.prev.title.replace(/[\u00A0-\u9999<>&]/g, i => '&#'+i.charCodeAt(0)+';')}</h2>
									<img src={`/media/game/${responseData.prev.images[0]}`} alt='gameImage' />
								</Box>
							}
							placement="top"
						>
							<Link to={"/game/" + (responseData.prev.uri !== undefined && responseData.prev.uri !== null ? responseData.prev.uri : responseData.prev.id)}
								data-tooltip-id="react-tooltip-data-html"
								data-tooltip-html={`<div class=tooltipContent>
									${responseData.prev.title.replace(/[\u00A0-\u9999<>&]/g, i => '&#'+i.charCodeAt(0)+';')}
									${responseData.prev.images.length ? '<img src="/media/game/' + responseData.prev.images[0] + '"/>' : ''}
								</div>`}
							>
								⏪
							</Link>
						</Tooltip>
					)}
				</span>
				<Link className="button" to="/games">Back to Games</Link>
				{/* <Box sx={{ display: 'inline-block'}}> */}
					{/* <ShareContent url={window.location.href} title={'Share this Project with Friends'} sx={{ display: 'inline-block'}} /> */}
				{/* </Box> */}
				<span className="next">
					{responseData.next && (
						<Tooltip
							title={
								<Box className="tooltipContent">
									<h2>{responseData.next.title.replace(/[\u00A0-\u9999<>&]/g, i => '&#'+i.charCodeAt(0)+';')}</h2>
									<img src={`/media/game/${responseData.next.images[0]}`} alt='gameImage' />
								</Box>
							}
							placement="top"
						>
							<Link to={"/game/" + (responseData.next.uri !== undefined && responseData.next.uri !== null ? responseData.next.uri : responseData.next.id)}
								data-tooltip-id="react-tooltip-data-html"
								data-tooltip-html={`<div class=tooltipContent>
									${responseData.next.title.replace(/[\u00A0-\u9999<>&]/g, i => '&#'+i.charCodeAt(0)+';')}
									${responseData.next.images.length ? '<img src="/media/game/' + responseData.next.images[0] + '"/>' : ''}
								</div>`}
							>
								⏩
							</Link>
						</Tooltip>
					)}
				</span>
				<ShareContent url={window.location.href} tooltip='Share this Game' title={'Share this Game with Friends'} sx={{ position: 'absolute', top: 10, right: -10 }} buttonSx={{ minWidth: 'auto' }} />
			</Box>
		</div>
		)}
	</>
	)
}

export default GameDisplay;
