import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Link, useParams } from "react-router-dom";
import useFetchData from "helpers/useFetchData";
import ExperienceItemDetails from "components/ExperienceItemDetails";
import DisplayProgressCircles from "components/DisplayProgressCircles";
import { config } from '../Constants';

import "react-vertical-timeline-component/style.min.css";
import "../styles/Experience.css";
import usePageSEO from "hooks/usePageSEO";


const ExperienceDetails = ({type = ''}) => {
	const { src } = useParams();
	const url = config.url.API_URL + 'experience' + (type.length ? '/' + encodeURIComponent(type) : '') + (src.length ? '/' + encodeURIComponent(src) : '');
	const { data: item, loading, error, errorMessage, responseData } = useFetchData({ url });

	usePageSEO({
		title: `Abdullah Ibne Alam - ${type ? type === 'School' ? 'Education' : 'Work' : 'Experience'} - ${item?.institution?.replace(/<[^>]*>?/gm, '')} - ${item?.title}`,
		description: `Abdullah Ibne Alam - ${type ? type === 'School' ? 'Education' : 'Work' : 'Experience'} - ${item?.institution?.replace(/<[^>]*>?/gm, '')} - ${item?.title}`,
		ogImage: [`${window.location.origin}/media/RepulsiveCoder_FB.jpg`],
		ogUrl: window.location.href,
		ogType: 'portfolio'
	});

	return (
		<>
		{(loading || error) && (
		<Box sx={{ display: 'grid', placeItems: 'center', height: 'calc(100vh - 350px)'}}>
			{/* <h1>Experience</h1> */}
			{!error && (<DisplayProgressCircles />)}
			{error && (<div className="errorMsg">{errorMessage}</div>)}
		</Box>
		)}

		{(!loading && !!item && item.length !== 0) && (
		<div className="experience">
			<ExperienceItemDetails item={item} prev={responseData.prev} next={responseData.next} />
			{isMobile &&
				(
				<Box sx={{ width: 'calc(100% - 10px)'}}>
					<Link
						to={type === 'School' ? '/projects' : '/education'}
						className="button" style={{ float: 'left'}}
					>
						<span className="symbol prev">&laquo;</span>
						{type === 'School' ? 'Projects' : 'Education'}
					</Link>
					<Link
						to={type === 'School' ? '/work' : '/games'}
						className="button" style={{ float: 'right'}}
					>
						{type === 'School' ? 'Work' : 'Game'}
						<span className="symbol next">&raquo;</span>
					</Link>
				</Box>
			)}
		</div>
		)}
		</>
	);
}

export default ExperienceDetails;