import { Link, useParams } from "react-router-dom";
import GitHubIcon from "@mui/icons-material/GitHub";
import PreviewIcon from '@mui/icons-material/Preview';
import LinkIcon from '@mui/icons-material/Link';
import "../styles/ProjectDisplay.css";
import { config } from '../Constants'

// import { Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css'
import Moment from 'moment';
import { Box, Typography } from "@mui/material";
import { Tooltip } from 'react-tooltip';
import useFetchData from "helpers/useFetchData";
import DisplayProgressCircles from "components/DisplayProgressCircles";
import ShareContent from "components/ShareContent";
import usePageSEO from "hooks/usePageSEO";
import CustomSlider from "components/custom.slider";



const divStyle = {
	// display: 'flex',
	// alignItems: 'center',
	// justifyContent: 'center',
	// height: '400px'
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	borderRadius: '10px',
	height: '100%'
}


const ProjectDisplay = () => {
	const { src } = useParams();
	const url = config.url.API_URL + 'project/' + src;
	const { data: project, loading, error, errorMessage, responseData } = useFetchData({ url });

	usePageSEO({
		title: `RepulsiveCoder - Project - ${project?.title}`,
		description: `RepulsiveCoder - Project - ${project?.description?.replace(/<[^>]*>?/gm, '').slice(0, 250)}`,
		ogImage: project?.images??[`${window.location.origin}/media/RepulsiveCoder_FB.jpg`],
		ogUrl: window.location.href,
		ogType: 'portfolio'
	});

  return (
	<>
	{loading && (
	<div className="project">
		<h1>Project Details</h1>
		{(loading || error) && (
		<Box sx={{ display: 'grid', placeItems: 'center', height: 'calc(100vh - 550px)'}}>
			{!error && (<DisplayProgressCircles />)}
			{error && (<div className="errorMsg">{errorMessage}</div>)}
		</Box>
		)}
	</div>
	)}
	{(!loading && !!project) &&
	(
	<div className="project">
		<meta property="og:title" content={`RepulsiveCoder - Project - ${project.title}`} />
		<meta property="og:description" content={`RepulsiveCoder - Project - ${project.description.replace(/<[^>]*>?/gm, '').slice(0, 250)}`} />
		{project.images.length > 0 && project.images.map((ogImage, index) => <meta key={index} property="og:image" content={`/media/project/${ogImage}`} />)}
		<h1>
			<span className="prev">
				{responseData.prev && (
					<Link to={"/project/" + (responseData.prev.uri !== undefined && responseData.prev.uri !== null ? responseData.prev.uri : responseData.prev.id)}
						data-tooltip-id="react-tooltip-data-html"
						data-tooltip-html={`<div class=tooltipContent>
							${responseData.prev.title.replace(/[\u00A0-\u9999<>&]/g, i => '&#'+i.charCodeAt(0)+';')}
							${responseData.prev.images.length ? '<img src="/media/project/' + responseData.prev.images[0] + '"/>' : ''}
						</div>`}
					>
						⏪
					</Link>
				)}
			</span>
			<span className="next">
				{responseData.next && (
					<Link to={"/project/" + (responseData.next.uri !== undefined && responseData.next.uri !== null ? responseData.next.uri : responseData.next.id)}
						data-tooltip-id="react-tooltip-data-html"
						data-tooltip-html={`<div class=tooltipContent>
							${responseData.next.title.replace(/[\u00A0-\u9999<>&]/g, i => '&#'+i.charCodeAt(0)+';')}
							${responseData.next.images.length ? '<img src="/media/project/' + responseData.next.images[0] + '"/>' : ''}
						</div>`}
					>
						⏩
					</Link>
				)}
			</span>
			{project.title}
			<Tooltip id="react-tooltip-data-html" place="bottom" style={{ zIndex: 200 }} />
		</h1>
		{project?.images?.length > 1 && (
			// <div className="slide-container">
			// 	<Slide>
			// 	{project.images.map((slideImage, index)=> (
			// 		<div key={index} className="slide-image">
			// 			<div style={{ ...divStyle, 'backgroundImage': `url(/media/project/${slideImage})` }} />
			// 		</div>
			// 	))}
			// 	</Slide>
			// </div>

			<CustomSlider slideDelay={8000}>
				{project.images.map((slideImage, index)=> (
					<img key={index} src={`/media/project/${slideImage}`} alt={''} />
				))}
			</CustomSlider>
		)}

		{project?.images?.length === 1 && (<div className="slide-container"><div className="slide-image"><div style={{ ...divStyle, 'backgroundImage': `url(/media/project/${project.images[0]})` }} /></div></div>)}
		<Box component="fieldset" className="descriptionContainerFieldset" sx={{ animationDuration: '2s !important' }}>
			<Typography component="legend" >Description </Typography>
			<div className="description" dangerouslySetInnerHTML={{ __html: project.description }} />

			<div className="technology">
				<b>Technology:</b> {project.technology}
			</div>

			<div className="description">
				<b>Published:</b> {project.displayonlyyear === '1' ? Moment(project.published).format('Y') : project.hidedate === '1' ? Moment(project.published).format('MMMM Y') : Moment(project.published).format('MMMM D, Y')}
			</div>

			{!!project.dates && project.dates.length > 0 && (
				<div className="description">
				<b>Versions:</b>
				<div className="versions">
				{project.dates.map((dt, dk) => {
				return (<div key={dk}>{dt.version}, <b>Published:</b> {project.displayonlyyear === '1' ? Moment(dt.date).format('Y') : project.hidedate === '1' ? Moment(dt.date).format('MMMM Y') : Moment(dt.date).format('MMMM D, Y')}</div>);
				})}
				</div>
				</div>
			)}

			{(!!project.demo || !!project.url || !!project.github) && (
				<div className="external-links">
					{!!project.demo && (
						<a href={`${project.demo}`} target="_blank" className="button" rel="noreferrer"><PreviewIcon /> Demo</a>
					) }

					{!!project.url && (
						<a href={`${project.url}`} target="_blank" className="button" rel="noreferrer"><LinkIcon /> Website</a>
					) }

					{!!project.github && (
						<a href={`${project.github}`} target="_blank" className="button" rel="noreferrer"><GitHubIcon /> Github</a>
					) }
				</div>
			) }

		</Box>
		<Box className="buttonContainer" sx={{ display: 'grid', gridTemplateColumns: '1fr auto'}}>
			<Link className="button" to="/projects">Back to Project List</Link>
			<ShareContent url={window.location.href} title={'Share this Project with Friends'} buttonSx={{ minWidth: 'auto' }} />
		</Box>
	</div>
	)}
	</>
	)
}

export default ProjectDisplay;
