import { config } from '../Constants'
import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import DisplayProgressCircles from "components/DisplayProgressCircles";
import useFetchData from "helpers/useFetchData";
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import { useRef, useState } from 'react';
// import "../styles/Games.css";


const Profile = ({ profile = null, logIn = () => {}, token = null}) => {
	const [updateLoading, setUpdateLoading] = useState(false);
	const [updateSuccess, setUpdateSuccess] = useState(false);
	const url = config.url.API_URL + 'profile';
	const { data, loading, error, errorMessage } = useFetchData({ url, method: 'POST', body: JSON.stringify({ token }) });
	const elementRef = useRef(null);

	const updateInGameNick = (e) => {
		const value = elementRef.current.value;
		const abortController = new AbortController();
		e.preventDefault();

		if (value.length > 0) {
			(async () => {
				setUpdateLoading(true);
				const response = await fetch(url,
                    {
                        method: 'PATCH',
                        headers: {
                            'Content-Type' : 'application/json',
                            mode:'cors'
                        },
                        body: JSON.stringify({ token, ingamenick: value }),
                        signal: abortController.signal
                    }
                );

				if (response.status === 200) {
					setUpdateSuccess(true);
					setTimeout(() => setUpdateSuccess(false), 1000);
				}

				setUpdateLoading(false);
			})();
		}

        return () => {
            abortController.abort();
        }
	}

	return (
		<div className="games">
			{(loading || error) && (
			<Box sx={{ display: 'grid', placeItems: 'center', height: 'calc(100vh - 350px)'}}>
				{!error && (<DisplayProgressCircles />)}
				{error && (<div className="errorMsg">{errorMessage}</div>)}
			</Box>
			)}
			{(!loading && !!data) && (
			<div className="game">
				<h1>Profile</h1>
				<Table stickyHeader aria-label="sticky table" sx={{ maxWidth: '600px', margin: '0 auto 60px'}}>
					<TableBody>
						<TableRow>
							<TableCell colSpan={2} align='center'><img src={data.avatar ?? data.picture} alt="theuser" style={{ maxHeight: '180px', maxWidth: '180px' }}/></TableCell>
						</TableRow>
						<TableRow>
							<TableCell>UserID</TableCell>
							<TableCell>{data.userid}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>In Game Nick</TableCell>
							<TableCell>
							<Box component="form" onSubmit={updateInGameNick} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }} >
								<TextField defaultValue={data.ingamenick} size="small" inputRef={elementRef} />
								<Button type='submit' sx={{ minWidth: 0 }}>
									<SaveIcon sx={{ verticalAlign: 'bottom'}}/>
								</Button>
								{updateLoading && <CircularProgress sx={{padding: "10px"}} />}
								{updateSuccess && <CheckIcon color='success'/>}
							</Box>
						</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Service</TableCell>
							<TableCell>{data.service}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Email/UserID</TableCell>
							<TableCell>{data.email}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>{data.name}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Given Name</TableCell>
							<TableCell>{data.given_name}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Family Name</TableCell>
							<TableCell>{data.family_name}</TableCell>
						</TableRow>
					</TableBody>

				</Table>

				{isMobile && (
				<Box sx={{ width: 'calc(100% - 10px)'}}>
					<Link to="/work" className="button" style={{ float: 'left'}}><span className="symbol prev">&laquo;</span> Work</Link>
				</Box>
				)}
			</div>
			)}
		</div>
	);
}

export default Profile;
