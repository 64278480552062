import { AccountCircle, ContentCopy } from "@mui/icons-material";
import { Box, Button, Divider, Link, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

import ShareIcon from '@mui/icons-material/Share';
import useUpdateGameData from "games/helpers/useUpdateGameData";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import YoutubeIcon from "@mui/icons-material/YouTube";
// import GithubIcon from "@mui/icons-material/GitHub";
// import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import RedditIcon from "@mui/icons-material/Reddit";
import PinterestIcon from "@mui/icons-material/Pinterest";
import XIcon from '@mui/icons-material/X';
import SvgIcons from "../../components/SvgIcons";
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from "@mui/icons-material/Facebook";
import FacebookLoginForm from "components/FacebookLoginForm";
import ORCAIcon from "../../assets/orca_logo_128x80.png";

const BUTTON_SX = {
    padding: '0px 6px',
    margin: '0 2px -2px',
    ':hover': {
        color: '#36F !important'
    },
};

const ShareItem = ({ gameid = 'sliding-tiles', profile =  null, logIn = null, token = null, hash = null, shareTitle = 'Share your score with your friends', tooltip = '', tooltipPorps = { placement: 'top', arrow: true }}) => {
    const shareUrl = `${window.location.origin}/game/${gameid}/share/${hash}`;

    const { updateGameData } = useUpdateGameData();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleShareClick = async (type) => {
        const data = await updateGameData({ gameid, token, data: { hash, type }, hash });

        if (data && data.success) {
            if (type === 'copy') {
                await navigator.clipboard.writeText(shareUrl);
            }
            // setGameShareHash(data.hash);
        }

        handleClose();
        return true;
    }

    const { DiscordIcon } = SvgIcons();

    if (!hash) {
        // return;
    }

    return (
        <Tooltip title={tooltip}
        // @ts-ignore
        placement={tooltipPorps.placement} arrow={tooltipPorps.arrow}>
            <Box className="gameShareButtons">
                <Button
                    id="share-menu-button"
                    aria-controls={open ? 'share-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <ShareIcon />
                </Button>
                <Menu
                    id="login-logout-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'login-logout-menu-button',
                    }}
                >
                    {profile &&
                        <Box>
                            <Typography sx={{ padding: '3px 15px 8px', margin: '0 0' }}>{shareTitle}</Typography>
                            <Divider />
                            <MenuItem onClick={() => {  }}
                                sx={{
                                    padding: '15px 5px 8px',
                                    margin: 0,
                                    cursor: 'default',
                                    "&:hover": { backgroundColor: "transparent !important" }
                                }}
                            >
                                <Tooltip title="Copy Link" placement="top" arrow={true}>
                                    <Link
                                        onClick={() => { handleShareClick('copy'); }}
                                        sx={{ ...BUTTON_SX, cursor: 'pointer' }}
                                    >
                                        <ContentCopy fontSize="small" />
                                    </Link>
                                </Tooltip>
                                <Divider orientation="vertical" variant="fullWidth" flexItem />
                                <Tooltip title="Facebook" placement="top" arrow={true}>
                                    <Link
                                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`} target="_blank"
                                        onClick={() => { handleShareClick('fb'); }}
                                        sx={BUTTON_SX}
                                    >
                                        <FacebookIcon fontSize="small" />
                                    </Link>
                                </Tooltip>
                                <Divider orientation="vertical" variant="fullWidth" flexItem />
                                <Tooltip title="Twitter" placement="top" arrow={true}>
                                    <Link
                                        href={`https://x.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`} target="_blank"
                                        onClick={() => { handleShareClick('x'); }}
                                        sx={BUTTON_SX}
                                    >
                                        <XIcon fontSize="small" />
                                    </Link>
                                </Tooltip>
                                <Divider orientation="vertical" variant="fullWidth" flexItem />
                                <Tooltip title="X" placement="top" arrow={true}>
                                    <Link
                                        href={`https://web.whatsapp.com://send?text=${encodeURIComponent(shareUrl)}`} target="_blank"
                                        onClick={() => { handleShareClick('x'); }}
                                        sx={BUTTON_SX}
                                    >
                                        <WhatsAppIcon fontSize="small" />
                                    </Link>
                                </Tooltip>
                                <Divider orientation="vertical" variant="fullWidth" flexItem />
                                <Tooltip title="Pinterest" placement="top" arrow={true}>
                                    <Link
                                        href={`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(shareUrl)}`} target="_blank"
                                        onClick={() => { handleShareClick('x'); }}
                                        sx={BUTTON_SX}
                                    >
                                        <PinterestIcon fontSize="small" />
                                    </Link>
                                </Tooltip>
                                <Divider orientation="vertical" variant="fullWidth" flexItem />
                                <Tooltip title="Messenger" placement="top" arrow={true}>
                                    <Link
                                        href={`fb-messenger://share/?link=${encodeURIComponent(shareUrl)}`} target="_blank"
                                        onClick={() => { handleShareClick('x'); }}
                                        sx={BUTTON_SX}
                                    >
                                        <FacebookIcon fontSize="small" />
                                    </Link>
                                </Tooltip>
                                <Divider orientation="vertical" variant="fullWidth" flexItem />
                                <Tooltip title="LinkedIn" placement="top" arrow={true}>
                                    <Link
                                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}`} target="_blank"
                                        onClick={() => { handleShareClick('x'); }}
                                        sx={BUTTON_SX}
                                    >
                                        <LinkedInIcon fontSize="small" />
                                    </Link>
                                </Tooltip>
                                <Divider orientation="vertical" variant="fullWidth" flexItem />
                                <Tooltip title="Reddit" placement="top" arrow={true}>
                                    <Link
                                        href={`https://www.reddit.com/submit?url=${encodeURIComponent(shareUrl)}`} target="_blank"
                                        onClick={() => { handleShareClick('x'); }}
                                        sx={BUTTON_SX}
                                    >
                                        <RedditIcon fontSize="small" />
                                    </Link>
                                </Tooltip>
                            </MenuItem>
                        </Box>
                    }
                    {!profile &&
                    <Box>
                        <MenuItem>
                            <ListItemIcon>
                                <AccountCircle fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Please Sign In to Continue</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => { logIn(); handleClose(); }}>
                            <ListItemIcon>
                                <GoogleIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Sign in with Google
                            </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { logIn('discord'); handleClose(); }}>
                            <ListItemIcon>
                                <DiscordIcon width="22px" height="22px" />
                            </ListItemIcon>
                            <ListItemText>
                                Sign in with Discord
                            </ListItemText>
                        </MenuItem>
                        <FacebookLoginForm handleClose={() => handleClose()} logIn={(service, data) => logIn(service, data)}/>
                        <MenuItem onClick={() => { logIn('orca'); handleClose(); }}>
                            <ListItemIcon>
                            <img src={ORCAIcon} width="22px" height="22px" alt="ORCA" />
                            </ListItemIcon>
                            <ListItemText>
                                Sign in with ORCA
                            </ListItemText>
                        </MenuItem>
                    </Box>
                    }
                </Menu>
            </Box>
        </Tooltip>
    );
}

export default ShareItem;
