import { Box } from "@mui/material";
import "../styles/Content.css";
import useFetchData from "helpers/useFetchData";
import DisplayProgressCircles from "components/DisplayProgressCircles";


const Content = ({uri = ''}) => {
	const url = `content/${uri}.txt`;
	const { data: content, loading, error, errorMessage } = useFetchData({ url, type: 'text' });

	return (
		<>
		{(loading || error) && (
		<Box sx={{ display: 'grid', placeItems: 'center', height: 'calc(100vh - 350px)'}}>
			{!error && (<DisplayProgressCircles />)}
			{error && (<div className="errorMsg">{errorMessage}</div>)}
		</Box>
		)}
		{(!loading && !!content) && (
		<div className="contentContainer" dangerouslySetInnerHTML={{__html: content}} />
		)}
		</>
	);
}

export default Content;