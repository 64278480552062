import Moment from 'moment';

const getExperienceDateString = (item) => {
    var sdate = Moment(item.sdate).format('MMMM D, Y');
    var edate = Moment(item.edate).format('MMMM D, Y');

    if (item.displayonlyyear === 1) {
        sdate = Moment(item.sdate).format('Y');
        edate = Moment(item.edate).format('Y');
    } else if (item.hidedate === 1) {
        sdate = Moment(item.sdate).format('MMMM Y');
        edate = Moment(item.edate).format('MMMM Y');
    }

    var dateInfo = sdate + ' - ';

    if (item.currentlyworking === 1) {
        dateInfo += 'Current';
    } else {
        dateInfo += edate;
    }

    return dateInfo;
}

export default getExperienceDateString;