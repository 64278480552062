import parseTagData from "helpers/parseTagData";
import React from "react";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import AnimatedTextWriter from "./AnimatedTextWriter";
import { Box, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import ShareContent from "./ShareContent";
import getExperienceDateString from "helpers/getExperienceDateString";
// import { useNavigate } from "react-router-dom";
//import Moment from 'moment';

const ExperienceItemDetails = ({ item, prev = null, next = null }) => {
    const CustomIcon = item.type === 'School' ? <SchoolIcon /> : <WorkIcon />;
    const shareTitle = item.type === 'School' ? 'Education' : 'Experience';
    var type = item.type === 'School' ? 'Education' : item.type;

    let currentContent = item.description;
    const descriptionOutputs = [];
    let currentIndex = 1;
    while (true) {
        const { innerContent, attribContent, prevContent, nextContent }  = parseTagData(currentContent, 'AnimatedText', 'class');
        if (innerContent.length) {
            if (prevContent.length) {
                descriptionOutputs.push(<div key={`static_${currentIndex}`} dangerouslySetInnerHTML={{ __html: prevContent }} />);
            }

            let content = innerContent;
            let prefix = '';
            let suffix = '';
            let buttonText = '';
            let classStr = '';

            const h4Content = parseTagData(innerContent, 'h4', 'class');
            if (h4Content.innerContent.length) {
                buttonText = h4Content.innerContent;
                classStr = h4Content.attribContent + ' animatedText';
                prefix = h4Content.startTag + h4Content.innerContent + h4Content.closeTag;
                content = h4Content.nextContent;
            }

            if (attribContent !== 'showhide') {
                const olContent = parseTagData(innerContent, 'ol');
                const ulContent = parseTagData(innerContent, 'ul');

                if (olContent.innerContent.length) {
                    prefix = olContent.prevContent + olContent.startTag;
                    suffix = olContent.closeTag + olContent.nextContent;
                    content = olContent.innerContent;
                } else if (ulContent.innerContent.length) {
                    prefix = ulContent.prevContent + ulContent.startTag;
                    suffix = ulContent.closeTag + ulContent.nextContent;
                    content = ulContent.innerContent;
                }
            }

            descriptionOutputs.push(<AnimatedTextWriter key={`animated_${currentIndex}`} startDelay={50} delay={15} content={content} prefix={prefix} suffix={suffix} displayCodeWrapper={false} displayClickMoreButtonAndPause={false && attribContent !== 'autorun'} clickMoreHeaderText={buttonText} clickMoreHeaderClassString={classStr} displayCursorEnd={false} displayFullContentOnClickMoreButton={attribContent === 'showhide'} />);

            currentContent = nextContent;
        } else {
            descriptionOutputs.push(<div key={`static_${currentIndex}`} dangerouslySetInnerHTML={{ __html: currentContent }} />);
            break;
        }
        ++currentIndex;
    }

    return (
        <Box className="detailContainer">
            <h1 style={{ textAlign: 'center'}}>
                <span className="prev">
                    {prev && (
                        <Tooltip
                            title={<Box className='prevNextContainer'><h3>{prev.title}</h3><h4>{getExperienceDateString(prev)}</h4><h4 className='institution' dangerouslySetInnerHTML={{ __html: prev.institution }} /></Box>}
                            placement="top"
                        >
                            <Link to={`/${type.toLowerCase()}/${prev.uri !== undefined && prev.uri !== null ? prev.uri : prev.id}`} >
                                ⏪
                            </Link>
                        </Tooltip>
                    )}
                </span>
                {type}: {item.title} <ShareContent url={window.location.href} tooltip={`Share this ${shareTitle}`} title={`Share this ${shareTitle} with Friends`} sx={{ display: 'inline' }} iconSx={{ fontSize: 'min(30px, max(1.8vw, 14px))'}} buttonSx={{ minWidth: 'auto' }} />
                <span className="next">
                    {next && (
                        <Tooltip
                            title={<Box className='prevNextContainer'><h3>{next.title}</h3><h4>{getExperienceDateString(next)}</h4><h4 className='institution' dangerouslySetInnerHTML={{ __html: next.institution }} /></Box>}
                            placement="top"
                        >
                            <Link to={`/${type.toLowerCase()}/${next.uri !== undefined && next.uri !== null ? next.uri : next.id}`} >
                                ⏩
                            </Link>
                        </Tooltip>
                    )}
                </span>
            </h1>
            <Box className="vertical-timeline-element-content" sx={{ margin: 0}}>
                {CustomIcon} <Box sx={{ float: 'right' }}>{getExperienceDateString(item)}</Box>
                {/* date={date} iconStyle={{ background: "#3e497a", color: "#fff" }} icon={CustomIcon}  */}
                <h3 className="vertical-timeline-element-title">{item.title}</h3>
                <h4 dangerouslySetInnerHTML={{ __html: item.institution }} />
                {descriptionOutputs.length ? descriptionOutputs : <div dangerouslySetInnerHTML={{ __html: item.description }} />}
                {/*<p style={{whiteSpace: "pre-wrap"}}>{item.description}</p>*/}
                {/* <div dangerouslySetInnerHTML={{ __html: item.description }} /> */}
            </Box>

            <Box className="buttonContainer">
                <Link className="button" to={`/${type.toLowerCase()}`}>Back to {type}</Link>
            </Box>
        </Box>
    );
}

export default ExperienceItemDetails;
