import { Box, Button } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import './SlidingTiles.css'
import ShareItem from "games/components/ShareItem";
import useUpdateGameData from "games/helpers/useUpdateGameData";
import DisplayProgressCircles from "components/DisplayProgressCircles";
import WinnerCard from "games/components/WinnerCard";
import { isMobile } from 'react-device-detect';

const Square = ({ index, value, onClick, gridSize, type = 'number' }) => {
    var startTimer;

    return (
        <button
            draggable="false"
            className="tileButton"
            onClick={() => onClick(index)}

            onTouchStart={() => {
                if (isMobile) {
                    onClick(index);
                }
            }}

            onMouseUp={(e) => { clearTimeout(startTimer); e.currentTarget.draggable = false; } }
            onMouseDown={(e) => { var a = e.currentTarget; startTimer = setTimeout(() => { a.draggable = true; }, 100); } }
            onDragStart={ev => ev.dataTransfer.setData("Text", index)}
            onDragOver={ev => ev.preventDefault()}
            onDrop={(event) => {
                event.preventDefault();
                if (value === (gridSize * gridSize)) {
                    const src = Number(event.dataTransfer.getData("Text"));
                    const neighbors = getNeighbours(index, gridSize);
                    if (neighbors.includes(src)) {
                        onClick(src);
                    }
                }
            }}

            style={{
                opacity: value === (gridSize * gridSize) ? 0 : 1,
                color: type === 'number' ? '' : 'transparent',
                backgroundImage: type === 'number' ? 'none' : `url(${require(`./assets/${type}`)})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'calc(max(min(90vw, calc(100vh - 450px)), 320px) - 20px)',
                backgroundPosition: `${((value-1) % gridSize) * 100 / (gridSize-1)}% ${Math.floor((value-1) / gridSize) * 100 / (gridSize-1)}%`,
            }}
        >
            <Box className="wrapperItem">
                {type === 'number' ? value : '.'}
            </Box>
        </button>
    );
}

const getNeighbours = (position, gridSize) => {
    const neighbors = [];
    const xPos = position % gridSize;
    const yPos = Math.floor(position / gridSize);

    if (xPos === 0) {
        neighbors.push(position + 1);
    } else if (xPos === gridSize-1) {
        neighbors.push(position - 1);
    } else {
        neighbors.push(position - 1);
        neighbors.push(position + 1);
    }

    if (yPos === 0) {
        neighbors.push(position + gridSize);
    } else if (yPos === gridSize-1) {
        neighbors.push(position - gridSize);
    } else {
        neighbors.push(position - gridSize);
        neighbors.push(position + gridSize);
    }

    return neighbors;
}

const getUpdatedPositions = (position, gridSize, positions) => {
    const neighbours = getNeighbours(position, gridSize);
    const numberOfGrids = gridSize * gridSize;

    for (const neighbour of neighbours) {
        if (positions[neighbour] === numberOfGrids) {
            positions[neighbour] = positions[position];
            positions[position] = numberOfGrids;
            return positions;
        }
    }
}

const shuffleGame = (positions, gridSize, shuffle = 0) => {
    if (shuffle === 0){
        shuffle = gridSize * 100;
    }
    const numberOfGrids = gridSize * gridSize;
    let initPosition = numberOfGrids-1;
    while (--shuffle) {
        const neighbours = getNeighbours(initPosition, gridSize);
        const position = Math.floor(Math.random() * neighbours.length);

        positions[initPosition] = positions[neighbours[position]];
        positions[neighbours[position]] = numberOfGrids;
        initPosition = neighbours[position];
    }
    return positions;
}

const boardTilesTypes = [
    {title: '1,2,3 ...', id: 'number', value: 'number' },
    {title: 'Circle', id: 'circle', value: 'circle_001.jpg'},
    {title: 'Kedi', id: 'kedi', value: 'kedi_001.jpg'},
    {title: 'Neko', id: 'neko', value: 'neko_001.jpg'},
    {title: 'Meaw', id: 'meaw', value: 'meaw_001.jpg'},
    {title: 'Flower', id: 'flower', value: 'flower_001.jpg'},
];

const SlidingTiles = ({ gameid = 'sliding-tiles', gridSize = 4, profile = null, logIn = () => {}, token = null, hash = null, type = 'number' }) => {
    const minGridSize = 3;
    const maxGridSize = 6;
    const { getGameData, updateGameData, getMinuteSecondString } = useUpdateGameData();
    const [currentGridSize, setCurrentGridSize] = useState(gridSize);
    const [loading, setLoading] = useState(false);
    const [gameScore, setGameScore] = useState(0);
    const [gameTime, setGameTime] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [canMoveTiles, setCanMoveTiles] = useState(true);
    const [startReplay, setStartReplay] = useState(false);
    const [flashString, setFlashString] = useState(null);
    const [replayIndex, setReplayIndex] = useState(0);
    const [loadedFromHistory, setLoadedFromHistory] = useState(false);
    const [loadedHistoryData, setLoadedHistoryData] = useState(null);
    const [reset, setReset] = useState(true);
    const [complete, setComplete] = useState(false);
    const [moves, setMoves] = useState([]);
    const [gameShareHash, setGameShareHash] = useState(null);
    const [historyUpdated, setHistoryUpdated] = useState(false);
    const [moveCount, setMoveCount] = useState(0);
    const [firstMoveTime, setFirstMoveTime] = useState(null);
    const [tickCount, setTickCount] = useState(0);
    const [timeElapsed, setTimeElapsed] = useState('');
    const [demoMoveCount, setDemoMoveCount] = useState(0);
    const [gameStarted, setGameStarted] = useState(false);
    const [drawBoardRefresh, setDrawBoardRefresh] = useState(true);
    const [positions, setPositions] = useState([]);
    const [currentStartPositions, setCurrentStartPositions] = useState([]);
    const [historyStartPositions, setHistoryStartPositions] = useState([]);
    const [squares, setSquares] = useState([]);
    const [boardWidth, setBoardWidth] = useState(0);
    const [boardTilesType, setBoardTilesType] = useState(`${boardTilesTypes.filter((boardType) => boardType.id === type).map((boardType) => boardType.value)}`);
    const boardElement = useRef(null);
    let intervalHandler = useRef(null);
    let intervalHandlerTime = useRef(null);

    const numberOfGrids = currentGridSize * currentGridSize;
    const gridTemplateRowColumns = '1fr '.repeat(currentGridSize);

    useEffect(() => {
        if (hash) {
            setLoading(true);
            setErrorMessage(null);
            if (profile) {
                setGameShareHash(hash);
            }
            getGameData({ gameid, hash, token }).then((data) => {
                if (data.success) {
                    const game = data.data;
                    const currentGridSize = game.data.grid
                    setCurrentGridSize(currentGridSize);
                    setHistoryStartPositions(game.data.shuffle);
                    setBoardTilesType(game.data?.type??'number');
                    setLoading(false);
                    setLoadedFromHistory(true);
                    setLoadedHistoryData({game, user: data.user})

                    const positions = [];
                    const numberOfGrids = currentGridSize * currentGridSize;
                    for (let i = 1; i <= numberOfGrids; ++i) {
                        positions.push(i);
                    }
                    setPositions(positions);
                } else {
                    setErrorMessage(data.message);
                }
            });
        } else {
            setErrorMessage(null);
            setCurrentGridSize(gridSize);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        const handleWindowResize = () => {
            if (boardElement) {
                if (boardElement && boardElement.current) {
                    intervalHandler.current = setInterval(() => { handleWindowScroll() }, 50);
                    setTimeout(() => {
                        if (intervalHandler.current) {
                            clearInterval(intervalHandler.current)
                        }
                    }, 800);
                }
            }
        }

        window.addEventListener('resize', handleWindowResize);
        window.addEventListener('scroll', handleWindowScroll);

        setTimeout(() => { handleWindowScroll() }, 50)

        const intHandler = intervalHandlerTime;
        return () => {
            window.removeEventListener('resize', handleWindowResize);
            window.removeEventListener('scroll', handleWindowScroll);
            if (intHandler.current) {
                clearInterval(intHandler.current);
            }
        };

    }, []);

    useEffect(() => {
        if (boardElement) {
            handleWindowScroll();
        }
    }, [boardElement]);

    useEffect(() => {
        document.documentElement.style.setProperty('--grid-size', `${currentGridSize}`);
    }, [currentGridSize]);

    useEffect(() => {
        setDrawBoardRefresh(true);

        if (complete && !historyUpdated) {
            updateHistoryData();
            setHistoryUpdated(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameStarted, complete, historyUpdated]);

    useEffect(() => {
        if (drawBoardRefresh) {
            const tiles = getTiles();
            setSquares(tiles);
            setDrawBoardRefresh(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawBoardRefresh]);

    useEffect(() => {
        const tiles = getTiles();
        setSquares(tiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [positions]);

    useEffect(() => {
        if (reset) {
            const positions = [];
            for (let i = 1; i <= numberOfGrids; ++i) {
                positions.push(i);
            }

            setReset(false);
            resetGameVariables(positions);
        }
    }, [numberOfGrids, reset]);

    useEffect(() => {
        if (firstMoveTime !== null) {
            const elapsedMillis = new Date().getTime() - firstMoveTime;
            if (elapsedMillis < 0) {
                setFlashString(Math.abs(Math.floor(elapsedMillis/1000)));
                setTimeElapsed('00:00');
            } else {
                setFlashString(null);
                const timeString = getMinuteSecondString(elapsedMillis);
                setTimeElapsed(timeString);

                if (startReplay) {
                    const moves = loadedHistoryData.game.data.moves;
                    if (moves.length) {
                        if (replayIndex < moves.length) {
                            if (moves[replayIndex].time <= elapsedMillis) {
                                setReplayIndex((replayIndex) => { setMoveCount(replayIndex+1); return replayIndex+1; });
                                const newPositions = getUpdatedPositions(moves[replayIndex].position, currentGridSize, positions);
                                if (newPositions) {
                                    setPositions([...newPositions]);
                                }
                            }
                        } else {
                            setCanMoveTiles(false);
                            setStartReplay(false);
                            clearInterval(intervalHandler.current);
                        }
                    }
                }
            }
        }
    }, [firstMoveTime, moveCount, tickCount, startReplay, replayIndex, currentGridSize, loadedHistoryData, positions, getMinuteSecondString]);


    const resetGameVariables = (positions, started = false) => {
        setPositions(positions);
        setGameStarted(started);
        setFirstMoveTime(null);
        setTimeElapsed('');
        setMoveCount(0);
        setTickCount(0);
        setMoves([]);
        setCanMoveTiles(true);
        setReplayIndex(0);
        setStartReplay(false);
    }

    const startStopReplay = () => {
        if (!startReplay) {
            setFirstMoveTime(new Date().getTime() + 3000);
            setPositions([...historyStartPositions]);
            setReplayIndex(0);
            setMoveCount(0);
            setCanMoveTiles(false);
            intervalHandler.current = setInterval(() => { setTickCount((tickCount) => tickCount+1) }, 100);
            setFlashString('00:00');
            setStartReplay(true);
        } else {
            clearInterval(intervalHandler.current);
            setStartReplay(false);
        }
    }

    const updateHistoryData = async () => {
        const elapsedMillis = new Date().getTime() - firstMoveTime;
        const score = Math.floor(Math.pow(19, gridSize) * 100000 / (elapsedMillis * moveCount));
        setGameScore(score);
        setGameTime(elapsedMillis);
        const data = {
            grid: currentGridSize,
            type: boardTilesType,
            shuffle: currentStartPositions,
            moveCount,
            moves
        };
        const history = {
            gameid,
            pid: loadedFromHistory && loadedHistoryData ? loadedHistoryData.game.id : null,
            token,
            data: {
                score,
                time: elapsedMillis,
                data
            }
        };

        if (profile) {
            const updateData = await updateGameData(history);
            if (updateData && updateData.success) {
                setGameShareHash(updateData.hash);
            }
        } else {
            const histories = [];
            const historyString = localStorage.getItem('guest.games.histories');
            if (historyString) {
                const savedHistories = JSON.parse(localStorage.getItem('guest.games.histories'));
                for (const savedHistory of savedHistories) {
                    histories.push(savedHistory);
                }
            }
            delete history.token;
            history.created = new Date(new Date().getTime() + 3600000 * 6).toISOString().substring(0, 19).replace('T', ' ');
            histories.push(history);
            localStorage.setItem('guest.games.histories', JSON.stringify(histories));
        }
    }

    const handleWindowScroll = () => {
        if (boardElement && boardElement.current) {
            setBoardWidth(boardElement.current.clientWidth);
        }
    }

    const setGameStart = () => {
        setComplete(false);
        setHistoryUpdated(false);
        setGameShareHash(null);
        const positions = [];
        for (let i = 1; i <= numberOfGrids; ++i) {
            positions.push(i);
        }
        const newPositions = shuffleGame(positions, currentGridSize);
        setCurrentStartPositions([...newPositions]);
        resetGameVariables(newPositions, true);
    }

    const setRepeatStart = (history = false) => {
        setComplete(false);
        setHistoryUpdated(false);
        if (history && loadedFromHistory) {
            resetGameVariables([...historyStartPositions], true);
        } else {
            resetGameVariables([...currentStartPositions], true);
        }
    }

    const changeBoardType = () => {
        const boardIndex = boardTilesTypes.findIndex((boardType) => boardType.value === boardTilesType);
        if (boardIndex + 1 >= boardTilesTypes.length) {
            setBoardTilesType(boardTilesTypes[0].value);
        } else {
            setBoardTilesType(boardTilesTypes[boardIndex+1].value)
        }
        const positions = [];
        const numberOfGrids = currentGridSize * currentGridSize;

        for (let i = 1; i <= numberOfGrids; ++i) {
            positions.push(i);
        }
        resetGameVariables(positions, false);
    }

    const changeGridSize = () => {
        setCurrentGridSize((currentGridSize) => {
            if (++currentGridSize > maxGridSize) {
                currentGridSize = minGridSize;
            }

            const positions = [];
            const numberOfGrids = currentGridSize * currentGridSize;

            for (let i = 1; i <= numberOfGrids; ++i) {
                positions.push(i);
            }
            resetGameVariables(positions, false);

            document.documentElement.style.setProperty('--grid-size', `${currentGridSize}`);
            return currentGridSize;
        });
    }

    const checkGameScore = (positions) => {
        for (let i = 0; i < positions.length - 1; ++i) {
            if (positions[i] + 1 !== positions[i + 1]) {
                return false;
            }
        }
        return true;
    }

    const onClickTile = useCallback((i) => {
        if (!complete && canMoveTiles) {
            const newPositions = getUpdatedPositions(i, currentGridSize, positions);
            if (newPositions) {
                setPositions(newPositions);
                setDrawBoardRefresh(true);
                if (gameStarted) {
                    setMoveCount((moveCount) => moveCount+1);
                    if (!firstMoveTime) {
                        setMoves(moves => [...moves, {position: i, time: 0}]);
                        setFirstMoveTime(new Date().getTime());
                        intervalHandler.current = setInterval(() => { setTickCount((tickCount) => tickCount+1) }, 100);
                    } else {
                        setMoves(moves => [...moves, {position: i, time: (new Date().getTime() - firstMoveTime)}]);
                    }
                } else {
                    setDemoMoveCount((demoMoveCount) => demoMoveCount+1);
                }

                if (gameStarted && checkGameScore(newPositions)) {
                    setComplete(true);
                }
            }
        }
    }, [complete, currentGridSize, positions, gameStarted, firstMoveTime, canMoveTiles]);

    const getTiles = useCallback(() => {
        const numberOfGrids = currentGridSize * currentGridSize;
        const squares = [];

        for (let i = 0; i < numberOfGrids; ++ i) {
            squares.push(<Square key={i} index={i} value={positions[i]} gridSize={currentGridSize} onClick={onClickTile} type={boardTilesType} />)
        }
        return squares;
    }, [boardTilesType, currentGridSize, onClickTile, positions]);


    return (
        <Box className="gameContainerST">
            <Box className="gameTitle" sx={{ width: boardWidth }}>
                <h1>Sliding Tiles</h1>
            </Box>
            <Box className="scoreBoard" sx={{ width: boardWidth }}>
                {(gameStarted || startReplay || replayIndex > 0) && <><span className="highlight">Moves:</span> {moveCount}</>}
                &nbsp;
                <Box className="displayGridSize">
                    <Button className="displayGridSizeButton" onClick={changeGridSize}>
                        {`${currentGridSize} X ${currentGridSize}`}
                    </Button>
                    <Button className="displayGridSizeButton" onClick={changeBoardType}>
                        {boardTilesTypes.filter((boardType) => boardType.value === boardTilesType).map((boardType) => boardType.title)}
                    </Button>
                </Box>
                <span style={{ float: 'right' }}>
                    <span className="highlight">{complete ? getMinuteSecondString(gameTime) : timeElapsed}</span>

                    {/* { complete ? (<span className="highlight">End {getMinuteSecondString(gameTime)}</span>) : timeElapsed && gameStarted ? (<><span className="highlight">{timeElapsed}</span></>) : startReplay || replayIndex > 0 ? (<>Replay: <span className="highlight">{timeElapsed}</span></>) : (<><span className="highlight">Started:</span> {gameStarted ? 'Yes' : 'No'}</>)} */}
                </span>
            </Box>
            <Box ref={boardElement} className="gameBoardContainer" sx={{ gridTemplateColumns: gridTemplateRowColumns, gridTemplateRows: gridTemplateRowColumns }}>
                {loading ?
                <Box sx={{ display: 'grid', placeItems: 'center', width: 'min(90vw, calc(100vh - 470px))', aspectRatio: 1 }}>
                    {errorMessage && (<div className="errorMsg">{errorMessage}</div>)}
                    {!errorMessage && <DisplayProgressCircles />}
                </Box> :
                (
                    <>
                        {squares}
                        <Box className="wrapperContainer">
                        </Box>
                        {flashString && <Box className="bigFlashContainer">{flashString}</Box>}
                        {complete && <Box className="winnerContainer"><Box className="winnerText">Congratulations</Box><Box className="winnerText">Score: {gameScore}</Box></Box>}
                        {loadedFromHistory && !gameStarted && !complete && loadedHistoryData && !startReplay && replayIndex === 0 &&
                        <Box className="flashContainer">
                            <WinnerCard data={loadedHistoryData} datas={[{title: 'Moves', field: 'moveCount'}]} extras={[{title: 'Grid', value: `${loadedHistoryData.game.data.grid}X${loadedHistoryData.game.data.grid}`}]} />
                        </Box>}
                        {!gameStarted && !complete && demoMoveCount > 0 && (demoMoveCount % 5 === 0) && <Box className="flashContainer">Start Game to Continue</Box>}
                    </>
                )}
            </Box>
            <Box className="startButton" sx={{ width: boardWidth }}>
                <Box className="startButtonGrid">
                    {loadedFromHistory ?
                    <>
                        <Button className={gameStarted ? '' : 'startAnimation'} onClick={() => { setGameStart(); } }>
                            {complete ? 'Start New Game' : (gameStarted ? 'Restart' : 'Start Game')}
                        </Button>
                        {(profile && profile.id === loadedHistoryData.user.userid) ?
                        <Button onClick={() => { startStopReplay(); } }>
                            {startReplay ? 'Stop Replay' : 'Replay'}
                        </Button>
                        :
                        <Button className={gameStarted ? '' : 'startAnimation'} onClick={() => { setRepeatStart(true); } }>
                            {complete ? 'Challenge Again' : (gameStarted ? 'Restart Challenge' : 'Start Challenge')}
                        </Button>
                        }
                    </>
                    :
                    <>
                        <Button className={gameStarted ? '' : 'startAnimation'} onClick={() => { setGameStart(); } }>
                            {complete ? 'Start New Game' : (gameStarted ? 'Restart' : 'Start Game')}
                        </Button>
                        {gameStarted && moveCount === 0 && (
                        <Button onClick={() => { setReset(true); } } sx={{  }}>
                            Reset
                        </Button>
                        )}
                        {gameStarted && moveCount > 0 && (
                        <Button onClick={() => { setRepeatStart(); } } sx={{  }}>
                            Repeat Start
                        </Button>
                        )}
                    </>
                    }
                </Box>
                {(complete || (profile && loadedFromHistory && profile.id === loadedHistoryData.user.userid)) && <ShareItem gameid={gameid} profile={profile} logIn={logIn} token={token} hash={gameShareHash} tooltip="Share your Score" />}
            </Box>
        </Box>
    );
};


export default SlidingTiles;