import { Box, Tooltip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';

const GameItem = ({ game }) => {
    const navigate = useNavigate();

    // var date = Moment(game.published).format('MMMM D, Y');

    // if (game.displayonlyyear === 1) {
    //     date = Moment(game.published).format('Y');
    // } else if (game.hidedate === 1) {
    //     date = Moment(game.published).format('MMMM Y');
    // }

    return (
        <div
            className="gameItem"
            onClick={() => {
                navigate("/game/" + (game.uri !== undefined && game.uri !== null ? game.uri : game.id));
            }}
        >
            <div style={{ backgroundImage: `url(/media/game/${game.images[0]})` }} className="bgImage" />
            <Tooltip
                title={
                    <Box>
                        <b>Game Title:</b> {game.title}<br />
                        <b>Published on:</b> {Moment(game.published).format('MMMM D, Y')}<br />
                        {game.description && (<><br /><b>Description:</b> <div dangerouslySetInnerHTML={{ __html: game.description }} /><br /></>)}

                    </Box>
                }
                placement="top"
                arrow={true}
                enterTouchDelay={0}
                leaveTouchDelay={0}
            >
                <h1> {game.title} </h1>
            </Tooltip>
            {/* <p> {date} </p> */}
        </div>
    );
}

export default GameItem;
