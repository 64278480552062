import parseTagData from "helpers/parseTagData";
import React from "react";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import MoreIcon from '@mui/icons-material/More';
// import { VerticalTimelineElement } from "react-vertical-timeline-component";
import AnimatedTextWriter from "./AnimatedTextWriter";
import ShareContent from "./ShareContent";
import { Box, Button, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import getExperienceDateString from "helpers/getExperienceDateString";
// import { useNavigate } from "react-router-dom";
//import Moment from 'moment';

const ExperienceItem = ({ item, type = '' }) => {
    const itemUrl = `${type.length ? window.location.href : window.location.href.replace('/experience', item.type === 'School' ? '/education' : '/work')}/${item.uri ? item.uri : item.id}`;
    const CustomIcon = item.type === 'School' ? <SchoolIcon /> : <WorkIcon />;
    const shareTitle = item.type === 'School' ? 'Education' : 'Experience';

    let currentContent = item.description;
    const descriptionOutputs = [];
    let currentIndex = 1;
    while (true) {
        const { innerContent, attribContent, prevContent, nextContent }  = parseTagData(currentContent, 'AnimatedText', 'class');
        if (innerContent.length) {
            if (prevContent.length) {
                descriptionOutputs.push(<div key={`static_${currentIndex}`} dangerouslySetInnerHTML={{ __html: prevContent }} />);
            }

            let content = innerContent;
            let prefix = '';
            let suffix = '';
            let buttonText = '';
            let classStr = '';

            const h4Content = parseTagData(innerContent, 'h4', 'class');
            if (h4Content.innerContent.length) {
                buttonText = h4Content.innerContent;
                classStr = h4Content.attribContent + ' animatedText';
                prefix = h4Content.startTag + h4Content.innerContent + h4Content.closeTag;
                content = h4Content.nextContent;
            }

            if (attribContent !== 'showhide') {
                const olContent = parseTagData(innerContent, 'ol');
                const ulContent = parseTagData(innerContent, 'ul');

                if (olContent.innerContent.length) {
                    prefix = olContent.prevContent + olContent.startTag;
                    suffix = olContent.closeTag + olContent.nextContent;
                    content = olContent.innerContent;
                } else if (ulContent.innerContent.length) {
                    prefix = ulContent.prevContent + ulContent.startTag;
                    suffix = ulContent.closeTag + ulContent.nextContent;
                    content = ulContent.innerContent;
                }
            }

            descriptionOutputs.push(<AnimatedTextWriter key={`animated_${currentIndex}`} delay={20} content={content} prefix={prefix} suffix={suffix} displayCodeWrapper={false} displayClickMoreButtonAndPause={attribContent !== 'autorun'} clickMoreHeaderText={buttonText} clickMoreHeaderClassString={classStr} displayCursorEnd={false} displayFullContentOnClickMoreButton={attribContent === 'showhide'} />);

            currentContent = nextContent;
        } else {
            descriptionOutputs.push(<div key={`static_${currentIndex}`} dangerouslySetInnerHTML={{ __html: currentContent }} />);
            break;
        }
        ++currentIndex;
    }

    return (
        <>
        <div className="vertical-timeline-element--education vertical-timeline-element">
            <span style={{ background: "#3e497a", color: "#fff" }} className="vertical-timeline-element-icon bounce-in">{CustomIcon}
            </span>
        {/* <VerticalTimelineElement className="vertical-timeline-element--education" date={getExperienceDateString(item)} iconStyle={{ background: "#3e497a", color: "#fff" }} icon={CustomIcon} > */}
        <div className="vertical-timeline-element-content bounce-in">
            <div className="vertical-timeline-element-content-arrow"></div>
            {/* {CustomIcon} <Box sx={{ display: 'inline-block', margin: '2px 0 10px 10px', verticalAlign: 'top' }}>{getExperienceDateString(item)}</Box> */}
            <h3 className="vertical-timeline-element-title">
                {item.title}
                <Box sx={{float: 'right'}}>
                    <Tooltip title="View Details" placement="top" arrow={true}>
                        <Button component={Link} to={itemUrl} sx={{ minWidth: 'auto' }}>
                            <MoreIcon />
                        </Button>
                    </Tooltip>
                    <ShareContent url={itemUrl} tooltip={`Share this ${shareTitle}`} title={`Share this ${shareTitle} with Friends`} sx={{ display: 'inline' }} iconSx={{ fontSize: '25px'}} buttonSx={{ minWidth: 'auto' }} />
                </Box>
            </h3>
            <h4 dangerouslySetInnerHTML={{ __html: item.institution }} />
            {descriptionOutputs.length ? descriptionOutputs : <div dangerouslySetInnerHTML={{ __html: item.description }} />}
            <div></div>
            <span className="vertical-timeline-element-date">{getExperienceDateString(item)}</span>
        </div>
        {/* </VerticalTimelineElement> */}
        </div>
        </>
    );
}

export default ExperienceItem;
