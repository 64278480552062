const { useEffect } = require("react")


const usePageSEO = ({
    title,
    description = '',
    keywords = [],
    ogTitle = '',
    ogDescription = '',
    ogImage = [],
    ogType = '',
    ogUrl = ''
}) => {
    useEffect(() => {
        document.title = title
        setMetaTag('name', 'description', description);
        setMetaTag('name', 'keywords', keywords);
        setMetaTag('property', 'og:title', ogTitle || title);
        setMetaTag('property', 'og:description', ogDescription || description);
        setMetaTag('property', 'og:image', ogImage, false);
        setMetaTag('property', 'og:type', ogType);
        setMetaTag('property', 'og:url', ogUrl || window.location.href);

        return () => {
            //
        }

    }, [ title, description, keywords, ogTitle, ogDescription, ogImage, ogType, ogUrl ]);

    const setMetaTag = (attr, key, content, arrayMultipleData = false) => {
        if (content) {
            let element = document.querySelector(`meta[${attr}="${key}"]`);

            if (!element) {
                element = document.createElement('meta');
                element.setAttribute(attr, key);
                document.head.appendChild(element);
            }

            if (Array.isArray(content)) {
                if (arrayMultipleData) {
                    const elements = document.head.querySelectorAll(`meta[${attr}="${key}"]`);
                    let i = 0;
                    // console.log('elements', elements)
                    // console.log('Page: ', window.location.href)
                    if (elements.length > 1) {
                        i = 0;
                        elements.forEach(node => {
                            if (!node.hasChildNodes())
                            // if (i > 0) {
                                node.remove();
                                // document.head.removeChild(node);
                            // }
                            // ++i;
                        });
                    }

                    i = 0;
                    const l = content.length;
                    for (const cont of content) {
                        element.setAttribute('content', cont);
                        if (++i < l) {
                            element = document.createElement('meta');
                            element.setAttribute(attr, key);
                            element.setAttribute('key', `${attr}_${key}_${new Date().getTime()}`);
                            document.head.appendChild(element);
                        }
                    }
                } else {
                    element.setAttribute('content', content.join(', '));
                }
            } else {
                element.setAttribute('content', content);
            }
        }
    }
}

export default usePageSEO;