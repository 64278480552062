import ProjectItem from "../components/ProjectItem";
import { config } from '../Constants'
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import DisplayProgressCircles from "components/DisplayProgressCircles";
import useFetchData from "helpers/useFetchData";

import "../styles/Projects.css";
import usePageSEO from "hooks/usePageSEO";


const Projects = () => {
	const url = config.url.API_URL + 'projects';
	const { data: items, loading, error, errorMessage } = useFetchData({ url });

	usePageSEO({
		title: 'Abdullah Ibne Alam - Projects',
		description: 'Abdullah Ibne Alam - Projects',
		ogImage: [`${window.location.origin}/media/project/Anemometer.png`],
		ogUrl: window.location.href,
		ogType: 'portfolio'
	});

	return (
		<div className="projects">
            {/* <meta property="og:title" content="Abdullah Ibne Alam - Projects" />
            <meta property="og:description" content="Abdullah Ibne Alam - Projects" />
            <meta property="og:image" content="/media/RepulsiveCoder_FB.jpg" />
            <meta property="og:type" content="portfolio" /> */}
			{(loading || error) && (
			<Box sx={{ display: 'grid', placeItems: 'center', height: 'calc(100vh - 350px)'}}>
				{!error && (<DisplayProgressCircles />)}
				{error && (<div className="errorMsg">{errorMessage}</div>)}
			</Box>
			)}
			{(!loading && !!items && items.length) && (
			<div className="projectList">
				{items.map((item, index) => <ProjectItem key={index} project={item} />)}

				{isMobile && (
				<Box sx={{ width: 'calc(100% - 10px)'}}>
					<Link to="/" className="button" style={{ float: 'left'}}><span className="symbol prev">&laquo;</span> Home</Link>
					<Link to="/education" className="button" style={{ float: 'right'}}>Education <span className="symbol next">&raquo;</span></Link>
				</Box>
				)}
			</div>
			)}
		</div>
	);
}

export default Projects;
