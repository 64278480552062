const { CircularProgress, Stack } = require("@mui/material");


const DisplayProgressCircles = ({ n=3, colors=['#C66', '#6C6', '#66C'] }) => {
    const circles = [];

    for (let i = 0; i < n; ++i) {
        circles.push(<CircularProgress key={i} sx={{ color: i < colors.length ? colors[i] : colors[i%colors.length] }} />);
    }

    return (<Stack spacing={2} direction="row"> {circles} </Stack>);
}

export default DisplayProgressCircles;