import React from "react";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YoutubeIcon from "@mui/icons-material/YouTube";
import GithubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from '@mui/icons-material/X';
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";

import "../styles/Footer.css";


const TikTokIcon = ({ color = "#FFFFFF" }) => {
    return (
        <div style={{height: '60px', width: '60px', display: 'inline-block' }}>
            <svg fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
                <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"/>
            </svg>
        </div>
    );
};
// const TikTokIcon = ({ color = "#FFFFFF" }) => {
//     return (
//         <div style={{height: '60px', width: '80px', display: 'inline-block' }}>
//             <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 50">
//                 <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
//             </svg>
//         </div>
//     );
// };

const TwitchIcon = ({ color = "#FFFFFF" }) => {
    return (
        <div style={{height: '60px', width: '60px', display: 'inline-block' }}>
            <svg fill={color} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="60" height="60" viewBox="-5 0 60 55">
                <path d="M 5.3125 1 L 2 9.8125 L 2 43 L 13 43 L 13 49 L 20.40625 49 L 26.40625 43 L 35.40625 43 L 48 30.4375 L 48 1 Z M 11 6 L 43 6 L 43 28 L 37 34 L 25 34 L 19 40 L 19 34 L 11 34 Z M 20 13 L 20 27 L 26 27 L 26 13 Z M 30 13 L 30 27 L 36 27 L 36 13 Z"/>
            </svg>
        </div>
    );
};

const FlickrIcon = ({ color = "#FFFFFF" }) => {
    return (
        <div style={{height: '60px', width: '60px', display: 'inline-block' }}>
            <svg className="flickr" fill={color} xmlns="http://www.w3.org/2000/svg"    viewBox="0 -2 48 48" width="60px" height="60px">
                <path d="M6,10c0-2.2,1.8-4,4-4h28c2.2,0,4,1.8,4,4v28c0,2.2-1.8,4-4,4H10c-2.2,0-4-1.8-4-4V10z"/>
                <path className="blue" d="M16 17A7 7 0 1 0 16 31A7 7 0 1 0 16 17Z"/>
                <path className="purple" d="M32 17A7 7 0 1 0 32 31A7 7 0 1 0 32 17Z"/>
            </svg>
        </div>
    );
};


const Footer = () => {
    return (
        <div className="footer">
            <div className="socialMedia">
                <a href="https://www.linkedin.com/in/abdullah-ibne-alam/" title="Abdullah Ibne Alam - LinkedIn Profile" target="_blank" rel="noreferrer" className="linkedin"><LinkedInIcon /></a>
                <a href="https://github.com/RepulsiveCoder/" title="Abdullah Ibne Alam - Github" target="_blank" rel="noreferrer" className="github"><GithubIcon /></a>
                <a href="https://www.facebook.com/RepulsiveCoder" title="Abdullah Ibne Alam - Facebook Profile" target="_blank" rel="noreferrer" className="facebook"><FacebookIcon /></a>
                <a href="https://www.twitch.tv/RepulsiveCoder" title="Abdullah Ibne Alam - Twitch" target="_blank" rel="noreferrer" className="twitch"><TwitchIcon /></a>
                <a href="https://www.tiktok.com/@repulsivecoder" title="Abdullah Ibne Alam - Tiktok" target="_blank" rel="noreferrer" className="tiktok"><TikTokIcon /></a>
                <a href="https://youtube.com/@RepulsiveCoderHome" title="Abdullah Ibne Alam - Youtube" target="_blank" rel="noreferrer" className="youtube"><YoutubeIcon /></a>
                <a href="https://www.instagram.com/RepulsiveCoder/profilecard/" title="Abdullah Ibne Alam - Instagram" target="_blank" rel="noreferrer" className="instagram"><InstagramIcon /></a>
                <a href="https://www.flickr.com/photos/repulsivecoder/" title="Abdullah Ibne Alam - Flickr" target="_blank" rel="noreferrer" className="flickr"><FlickrIcon /></a>
                <a href="https://x.com/RepulsiveCoder" title="Abdullah Ibne Alam - X" target="_blank" rel="noreferrer" className="x"><XIcon /></a>
            </div>
            <span className="privacyterms">
                <Link to="/toc.html">Terms and Condition</Link> &nbsp; <span style={{ color: '#448' }}>|</span> &nbsp; <Link to="/privacy.html">Privacy Policy</Link>
			</span>
            <p> &copy; 2023 - {new Date().getFullYear()} repulsivecoder.pro</p>
        </div>
    );
}

export default Footer;
