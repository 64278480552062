import React, { useState } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import LogoutIcon from '@mui/icons-material/Logout';
import SvgIcons from "./SvgIcons";
import GoogleIcon from '@mui/icons-material/Google';
import '../styles/LoginLogoutMenu.css';
import FacebookLoginForm from "./FacebookLoginForm";
import ORCAIcon from "../assets/orca_logo_128x80.png";


const LoginLogoutMenu = ({ profile, logIn, logOut, footer=false }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const { DiscordIcon } = SvgIcons();

    return (
        <>
            <Button
                id="login-logout-menu-button"
                aria-controls={open ? 'login-logout-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ padding: 0, margin: 0, minWidth: 0, color: '#FFF' }}
            >
                { profile ?
                    <img src={profile.avatar ?? profile.picture} alt="theuser" className={`${footer ? 'footerMenu' : ''} theLoggedInUser`} />
                    :
                    <AccountCircleIcon sx={{ cursor: 'pointer' }} className={`${footer ? 'footerMenu' : ''} theLoggedOutUser`} />
                }
            </Button>
            <Menu
                id="login-logout-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'login-logout-menu-button',
                }}
            >
                { profile ?
                    <Box>
                        <MenuItem onClick={() => { navigate("/profile"); handleClose(); }}>
                            <ListItemIcon>
                                <PersonOutlineIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Profile
                            </ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => { navigate("/games/history"); handleClose(); }}>
                            <ListItemIcon>
                                <SportsEsportsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                My Games
                            </ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => { logOut(); handleClose(); }}>
                            <ListItemIcon>
                                <LogoutIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Logout
                            </ListItemText>
                        </MenuItem>
                    </Box>
                    :
                    <Box>
                        <MenuItem onClick={() => { logIn(); handleClose(); }}>
                            <ListItemIcon>
                                <GoogleIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                Sign in with Google
                            </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { logIn('discord'); handleClose(); }}>
                            <ListItemIcon>
                                <DiscordIcon width="22px" height="22px" />
                            </ListItemIcon>
                            <ListItemText>
                                Sign in with Discord
                            </ListItemText>
                        </MenuItem>
                        <FacebookLoginForm handleClose={() => handleClose()} logIn={(service, data) => logIn(service, data)}/>
                        <MenuItem onClick={() => { logIn('orca'); handleClose(); }}>
                            <ListItemIcon>
                            <img src={ORCAIcon} width="22px" height="22px" alt="ORCA" />
                            </ListItemIcon>
                            <ListItemText>
                                Sign in with ORCA
                            </ListItemText>
                        </MenuItem>
                    </Box>
                }
            </Menu>
        </>
    );
}

export default LoginLogoutMenu;
