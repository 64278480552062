import GameItem from "components/GameItem";
import { config } from '../Constants'
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import DisplayProgressCircles from "components/DisplayProgressCircles";
import useFetchData from "helpers/useFetchData";

import "../styles/Games.css";
import usePageSEO from "hooks/usePageSEO";


const Games = () => {
	const url = config.url.API_URL + 'games';
	const { data: items, loading, error, errorMessage } = useFetchData({ url });

	usePageSEO({
		title: 'Abdullah Ibne Alam - Games',
		description: 'Abdullah Ibne Alam - Games',
		ogImage: [`${window.location.origin}/media/game/index.png`],
		ogUrl: window.location.href,
		ogType: 'portfolio'
	});

	return (
		<div className="games">
			<meta property="og:title" content='RepulsiveCoder - Games' />
			<meta property="og:description" content='RepulsiveCoder - Games' />
			<meta property="og:image" content='/media/game/index.png' />
			{(loading || error) && (
			<Box sx={{ display: 'grid', placeItems: 'center', height: 'calc(100vh - 350px)'}}>
				{!error && (<DisplayProgressCircles />)}
				{error && (<div className="errorMsg">{errorMessage}</div>)}
			</Box>
			)}
			{(!loading && !!items && items.length) && (
			<div className="gameList">
				{items.map((item, index) => <GameItem key={index} game={item}  />)}

				{isMobile && (
				<Box sx={{ width: 'calc(100% - 10px)'}}>
					<Link to="/work" className="button" style={{ float: 'left'}}><span className="symbol prev">&laquo;</span> Work</Link>
					{/* <Link to="/" className="button" style={{ float: 'right'}}>Home <span className="symbol next">&raquo;</span></Link> */}
				</Box>
				)}
			</div>
			)}
		</div>
	);
}

export default Games;
