import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Experience from "./pages/Experience";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ProjectDisplay from "./pages/ProjectDisplay";
import 'react-slideshow-image/dist/styles.css';
import { Box } from "@mui/material";
import Content from "pages/Content";
// import { googleLogout, useGoogleLogin } from '@react-oauth/google';
// import { useEffect, useState } from "react";
import FooterSticky from "components/FooterSticky";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Games from "pages/Games";
import GameDisplay from "pages/GameDisplay";
// import { config } from 'Constants';
import useUserAuthentication from "helpers/useUserAuthentication";
import GamesHistory from "pages/GamesHistory";
import Profile from "pages/Profile";
import ExperienceDetails from "pages/ExperienceDetails";


const App = () => {
    const { logIn, logOut, profile, token, redirect, setCode,setDoneRedirection } = useUserAuthentication();
    const darkTheme = createTheme({ palette: { mode: 'dark', }, });


    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className="App">
                <Router>
                    <Navbar profile={profile} logIn={logIn} logOut={logOut} redirect={redirect} setDoneRedirection={setDoneRedirection} shrinkAtStartRoutes={['/game/']}/>
                    <Box className="mainContainer">
                        <Routes>
                            <Route path="/" element={<Home profile={profile} logIn={logIn} token={token} />} />
                            <Route path="/projects" element={<Projects />} />
                            <Route path="/project/:src" element={<ProjectDisplay />} />
                            <Route path="/education/:src" element={<ExperienceDetails type="School" />} />
                            <Route path="/work/:src" element={<ExperienceDetails type="Work" />} />
                            <Route path="/education" element={<Experience type="School" />} />
                            <Route path="/work" element={<Experience type="Work" />} />
                            <Route path="/experience" element={<Experience  />} />
                            <Route path="/toc.html" element={<Content uri="toc" />} />
                            <Route path="/privacy.html" element={<Content uri="privacy" />} />
                            <Route path="/profile" element={<Profile profile={profile} logIn={logIn} token={token} />} />
                            <Route path="/games" element={<Games />} />
                            <Route path="/games/history" element={<GamesHistory profile={profile} logIn={logIn} token={token} />} />
                            <Route path="/game/:src" element={<GameDisplay profile={profile} logIn={logIn} token={token} />} />
                            <Route path="/game/:src/share/:hash" element={<GameDisplay profile={profile} logIn={logIn} token={token} />} />
                            <Route path="/oauth2/:service" element={<Home profile={profile} logIn={logIn} token={token} setCode={setCode} />} />
                        </Routes>
                    </Box>
                    <Footer />
                    <FooterSticky profile={profile} logIn={logIn} logOut={logOut} />
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;
