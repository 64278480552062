import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { config } from "Constants";
import useUpdateGameData from "games/helpers/useUpdateGameData";
import { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ORCAIcon from "../assets/orca_logo_128x80.png";

const useUserAuthentication = () => {
    const [ user, setUser ] = useState(null);
    const [ token, setToken ] = useState(null);
    const [ profile, setProfile ] = useState(null);
    const [ redirect, setRedirect ] = useState(null);
    const [ code, setCode ] = useState(null);
    const { updateGameData } = useUpdateGameData();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('user.auth.token'));
        const profile = JSON.parse(localStorage.getItem('user.auth.profile'));
        if (token) {
            setToken(token);
        }

        if (profile) {
            setProfile(profile);
        }
    }, []);

    useEffect(() => {
        if (user || code) {
            const url = config.url.API_URL + 'signin';
            let body;
            if (user) {
                body = JSON.stringify(user);
            } else {
                body = JSON.stringify({code, redirect_uri: config.url.DISCORD_REDIRECT, service: 'discord'});
            }

            fetch(url, {
                    method: 'POST',
                    headers: { 'Content-Type' : 'application/json' },
                    body,
                })
                .then((res) => {
                    return res.json();
                })
                .then(async (data) => {
                    if (data.success) {
                        setProfile(data.user);
                        setToken(data.token);
                        if (user?.service === 'orca') {
                            withReactContent(Swal).fire({
                                title: <><img src={ORCAIcon} alt="ORCA" /></>,
                                html: 'Sign In Successful.',
                                icon: 'success',
                                timer: 2000,
                                theme: 'dark'
                            });
                        }
                        localStorage.setItem('user.auth.token', JSON.stringify(data.token));
                        localStorage.setItem('user.auth.profile', JSON.stringify(data.user));
                        localStorage.setItem('user.auth.google.session', JSON.stringify(user));
                        const historyString = localStorage.getItem('guest.games.histories');
                        if (historyString) {
                            let lastGameId = '';
                            let shareHash = '';
                            const savedHistories = JSON.parse(localStorage.getItem('guest.games.histories'));
                            for (const savedHistory of savedHistories) {
                                const updateData = await updateGameData({ ...savedHistory, token: data.token });
                                if (updateData && updateData.success) {
                                    lastGameId = savedHistory.gameid;
                                    shareHash = updateData.hash;
                                }
                            }

                            localStorage.removeItem('guest.games.histories');

                            if (shareHash.length > 0) {
                                return setRedirect(`/game/${lastGameId}/share/${shareHash}`);
                            }
                        }
                        if (code) {
                            setRedirect(`/`);
                        }
                    } else {
                        if (user?.service === 'orca') {
                            withReactContent(Swal).fire({
                                title: <><img src={ORCAIcon} alt="ORCA" /></>,
                                html: 'Sign In Failed!',
                                icon: 'error',
                                timer: 2000,
                                theme: 'dark'
                            });
                        }
                    }
                })
                .catch((err) => {
                    setRedirect(`/`);
                    console.log(err);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, code] );

    const logIn = (service = 'google', data = null) => {
        if (service === 'discord') {
            document.location = config.url.DISCORD_URL;
        } else if (service === 'orca') {
            var usernameInput;
            var passwordInput;
            withReactContent(Swal).fire({
                title: <><img src={ORCAIcon} alt="ORCA" /></>,
                html: `
                    <input type="text" id="username" class="swal2-input" placeholder="Cadet No.">
                    <input type="password" id="password" class="swal2-input" placeholder="Password">
                `,
                confirmButtonText: 'Sign in',
                showCancelButton: true,
                focusConfirm: false,
                showLoaderOnConfirm: true,
                didOpen: () => {
                    const popup = Swal.getPopup()
                    usernameInput = popup.querySelector('#username');
                    passwordInput = popup.querySelector('#password');
                    // @ts-ignore
                    usernameInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm()
                    // @ts-ignore
                    passwordInput.onkeyup = (event) => event.key === 'Enter' && Swal.clickConfirm()
                  },
                preConfirm: async () => {
                    const cadet_no = usernameInput.value
                    const password = passwordInput.value
                    if (!cadet_no || !password) {
                        Swal.showValidationMessage(`Please enter Cadet No. and Password`);
                        return;
                    }

                    setUser({ cadet_no, password, service });

                    const delay = ms => new Promise(resolve => setTimeout(resolve, ms))
                    await delay(5000);
                },
                theme: 'dark'
              });
        } else if (service === 'facebook') {
            setUser({ user: data, service });
        } else {
            googleLogIn();
        }
    }

    const googleLogIn = useGoogleLogin({
        onSuccess: (codeResponse) => { setUser({ ...codeResponse, service: 'google' }); },
        onError: (error) => console.log('Login Failed:', error),
    });

    const logOut = () => {
        googleLogout();
        if (token) {
            const url = config.url.API_URL + 'signout';
            fetch(url, {
                    method: 'POST',
                    headers: { 'Content-Type' : 'application/json' },
                    body: JSON.stringify({token}),
                })
                .then((res) => {
                    return res.json();
                })
                .then(async (data) => {
                    if (data.success) {
                    }
                    console.log(data);
                })
                .catch((err) => console.log(err));
        }
        localStorage.removeItem('user.auth.token');
        localStorage.removeItem('user.auth.profile');
        localStorage.removeItem('user.auth.google.session');
        setProfile(null);
    };

    const setDoneRedirection = () => {
        setRedirect(null);
    }


    return {
        logIn,
        logOut,
        profile,
        token,
        redirect,
        setCode,
        setDoneRedirection
    }
}

export default useUserAuthentication;