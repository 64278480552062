// import { VerticalTimeline } from "react-vertical-timeline-component";
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import useFetchData from "helpers/useFetchData";
import ExperienceItem from "components/ExperienceItem";
import DisplayProgressCircles from "components/DisplayProgressCircles";
import { config } from '../Constants';

import "react-vertical-timeline-component/style.min.css";
import "../styles/Experience.css";
import usePageSEO from "hooks/usePageSEO";


const Experience = ({type = ''}) => {
	const url = config.url.API_URL + 'experience' + (type.length ? '/' + encodeURIComponent(type) : '');
	const { data: items, loading, error, errorMessage } = useFetchData({ url });

	usePageSEO({
		title: `Abdullah Ibne Alam - ${type ? type === 'School' ? 'Education' : 'Work' : 'Experience'}`,
		description: `Abdullah Ibne Alam - ${type ? type === 'School' ? 'Education' : 'Work' : 'Experience'}`,
		ogImage: [`${window.location.origin}/media/RepulsiveCoder_FB.jpg`],
		ogUrl: window.location.href,
		ogType: 'portfolio'
	});

	return (
		<>
		{(loading || error) && (
		<Box sx={{ display: 'grid', placeItems: 'center', height: 'calc(100vh - 350px)'}}>
			{/* <h1>Experience</h1> */}
			{!error && (<DisplayProgressCircles />)}
			{error && (<div className="errorMsg">{errorMessage}</div>)}
		</Box>
		)}

		{(!loading && !!items && items.length !== 0) && (
		<div className="experience">
            {/* <meta property="og:title" content={`Abdullah Ibne Alam - ${type.length ? type.charAt(0).toUpperCase() + type.slice(1) : ''}`} />
            <meta property="og:description" content={`Abdullah Ibne Alam - ${type.length ? type.charAt(0).toUpperCase() + type.slice(1) : ''}`} />
            <meta property="og:image" content="/media/RepulsiveCoder_FB.jpg" />
            <meta property="og:type" content="portfolio" /> */}
			{/* <VerticalTimeline lineColor='#3e497a'> */}
			<Box className="vertical-timeline vertical-timeline--animate vertical-timeline--two-columns">
				{items.map((item, index) => <ExperienceItem item={item} key={`${index}`} type={type} />)}
			</Box>
			{/* </VerticalTimeline> */}
			{isMobile &&
				(
				<Box sx={{ width: 'calc(100% - 10px)'}}>
					<Link
						to={type === 'School' ? '/projects' : '/education'}
						className="button" style={{ float: 'left'}}
					>
						<span className="symbol prev">&laquo;</span>
						{type === 'School' ? 'Projects' : 'Education'}
					</Link>
					<Link
						to={type === 'School' ? '/work' : '/games'}
						className="button" style={{ float: 'right'}}
					>
						{type === 'School' ? 'Work' : 'Game'}
						<span className="symbol next">&raquo;</span>
					</Link>
				</Box>
			)}
		</div>
		)}
		</>
	);
}

export default Experience;