import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import FacebookLogin from '@greatsumini/react-facebook-login' //'react-facebook-login/dist/facebook-login-render-props';

const FacebookLoginForm = (props) => {
    const handleFacebookCallback = (response) => {
        if (response?.status === "unknown") {
            console.error('Sorry!', 'Something went wrong with facebook Login.');
            return;
        }
        props.logIn('facebook', response);
    }

    return (
        <FacebookLogin
            // buttonStyle={{padding:"6px"}}
            appId="1137198901340159"
            autoLoad={false}
            fields="name,email,picture"
            onSuccess={handleFacebookCallback}
            render={renderProps => (
                <MenuItem onClick={() => { renderProps.onClick(); props.handleClose(); }}>
                    <ListItemIcon>
                        <FacebookIcon width="22px" height="22px" />
                    </ListItemIcon>
                    <ListItemText>
                        Sign in with Facebook
                    </ListItemText>
                </MenuItem>
              )}
        />
    );
};

export default FacebookLoginForm;