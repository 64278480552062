const parseTagData = (content = '', tag = '', attrib = '') => {
	const start = content.indexOf('<' + tag);

	let prevContent = content;
	let innerContent = '';
	let nextContent = '';
	let attribContent = '';
	let startTag = '';
	const closeTag = `</${tag}>`;
	if (start !== -1) {
		const close = content.indexOf('>', start);
		if (close !== -1) {
			const end = content.indexOf(closeTag, start);

			if (end !== -1) {
				if (attrib.length && close > start + `<${tag}`.length) {
					const attribStart = content.indexOf(`${attrib}="`, start);
					if (attribStart !== -1) {
						const atrribEnd =content.indexOf('"', attribStart+7);
						if (atrribEnd !== -1) {
							attribContent = content.substring(attribStart+`${attrib}="`.length, atrribEnd);
						}
					}
				}

				startTag = content.substring(start, close+1);
				prevContent = content.substring(0, start);
				innerContent = content.substring(close + 1, end);
				nextContent = content.substring(end + closeTag.length);
			}
		}
	}

	return { innerContent, attribContent, prevContent, nextContent, startTag, closeTag };
}

export default parseTagData;
