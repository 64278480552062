import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReorderIcon from "@mui/icons-material/Reorder";
import RepulsiveCoder from "../assets/RepulsiveCoder.png";
import LoginLogoutMenu from "./LoginLogoutMenu";
// import UserAuthentication from "helpers/useUserAuthentication";

import "../styles/Navbar.css";


const Navbar = ({ profile, logIn, logOut, redirect = null, setDoneRedirection = () => {}, shrinkAtStartRoutes = [], autoShrink = true }) => {
    // const { logIn, logOut, profile } = UserAuthentication();
    const [expandNavbar, setExpandNavbar] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [width, setWidth] = useState(0);
    const [navbarClass, setnavbarClass] = useState('navbar');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (redirect) {
            navigate(redirect);
            setDoneRedirection();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirect]);

    useEffect(() => {
        setExpandNavbar(false);
    }, [location]);

    useEffect(() => {
        if (autoShrink) {
            if (scrollPosition > 50) {
                setnavbarClass('navbar shrink');
            } else {
                setnavbarClass('navbar');
            }
        }
    }, [scrollPosition, autoShrink]);

    useEffect(() => {
        if (width > 600) {
            setExpandNavbar(false);
        } else {
        }
    }, [width]);

    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    const handleResize = () => {
        setWidth(window.innerWidth);
    }


    return (
        <div className={`${navbarClass} ${ shrinkAtStartRoutes.findIndex((sw) => location.pathname.startsWith(sw)) !== -1 ? 'shrink' : '' }`} id={expandNavbar ? "open" : "close"} >
            <div className="toggleButton">
                <button
                    onClick={() => {
                        setExpandNavbar((prev) => !prev);
                    }} >
                    <ReorderIcon />
                </button>
            </div>
            <div className="menuContainer">
                <div className="links">
                    <Link to="/" className="thehooman"><img src={RepulsiveCoder} id="thehooman" alt="thehooman" /></Link>
                    <Link to="/" className={location.pathname === '/' ? 'active' : ''}> Home </Link>
                    <Link to="/projects" className={location.pathname.startsWith('/project') ? 'active' : ''}> Projects </Link>
                    <Link to="/education" className={location.pathname.startsWith('/education') ? 'active' : ''}> Education </Link>
                    <Link to="/work" className={location.pathname.startsWith('/work') ? 'active' : ''}> Work </Link>
                    <Link to="/games" className={location.pathname.startsWith('/game') ? 'active' : ''}> Game </Link>
                </div>
            </div>
            <div className="loginLogoutMenu">
                <LoginLogoutMenu profile={profile} logIn={logIn} logOut={logOut} />
            </div>
        </div>
    );
}

export default Navbar;
