const prod = {
	url: {
		// API_URL: '/api/', // PHP API
		API_URL: 'https://api.repulsivecoder.pro/',
		DISCORD_REDIRECT:
			window.location.href.startsWith('https://abdullahibnealam.com/') ?
				'https://abdullahibnealam.com/oauth2/discord'
				:
				'https://repulsivecoder.pro/oauth2/discord',
		DISCORD_URL: `https://discord.com/oauth2/authorize?client_id=1319735901185904752&response_type=code&scope=identify&redirect_uri=`,
	}
};

const dev = {
	url: {
		// API_URL: 'http://localhost/repulsivecoder.pro/api/', // PHP API
		API_URL: 'http://localhost:4000/',
		DISCORD_REDIRECT: 'http://localhost:3000/oauth2/discord',
		DISCORD_URL: `https://discord.com/oauth2/authorize?client_id=1319735901185904752&response_type=code&scope=identify&redirect_uri=`,
	}
};

const dev_ssh = {
	url: {
		// API_URL: 'http://172.16.1.13/repulsivecoder.pro/api/', // PHP API
		API_URL: window.location.href.startsWith('https://git.repulsivecoder.pro') ? 'https://git.repulsivecoder.pro/api/' : 'http://172.16.1.13:4000/',
		// API_URL: 'http://localhost:4000/',
		DISCORD_REDIRECT: window.location.href.startsWith('http://localhost:3000/') ? 'http://localhost:3000/oauth2/discord' :
			window.location.href.startsWith('https://git.repulsivecoder.pro') ? 'https://git.repulsivecoder.pro/oauth2/discord' : 'http://172.16.1.13:3000/oauth2/discord',
		DISCORD_URL: `https://discord.com/oauth2/authorize?client_id=1319735901185904752&response_type=code&scope=identify&redirect_uri=`,
	}
};

prod.url.DISCORD_URL = `https://discord.com/oauth2/authorize?client_id=1319735901185904752&response_type=code&redirect_uri=${encodeURIComponent(prod.url.DISCORD_REDIRECT)}&scope=identify`;
dev.url.DISCORD_URL = `https://discord.com/oauth2/authorize?client_id=1319735901185904752&response_type=code&redirect_uri=${encodeURIComponent(dev.url.DISCORD_REDIRECT)}&scope=identify`;
dev_ssh.url.DISCORD_URL = `https://discord.com/oauth2/authorize?client_id=1319735901185904752&response_type=code&redirect_uri=${encodeURIComponent(dev_ssh.url.DISCORD_REDIRECT)}&scope=identify`;

export const config = process.env.NODE_ENV === 'development' ? (process.env.REACT_APP_RUN_REMOTE ? dev_ssh : dev) : prod;

// console.log(process.env);