import React from "react";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';
//import Moment from 'moment';

const ProjectItem = ({ project }) => {
    const navigate = useNavigate();

    var date = Moment(project.published).format('MMMM D, Y');

    if (project.displayonlyyear === 1) {
        date = Moment(project.published).format('Y');
    } else if (project.hidedate === 1) {
        date = Moment(project.published).format('MMMM Y');
    }

    return (
        <div
            className="projectItem"
            onClick={() => {
                navigate("/project/" + (project.uri !== undefined && project.uri !== null ? project.uri : project.id));
            }}
        >
            <div style={{ backgroundImage: `url(/media/project/${project.images[0]})` }} className="bgImage" />
            <h1> {project.title} </h1>
            <p> {date} </p>
        </div>
    );
}

export default ProjectItem;
