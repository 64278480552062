import { config } from '../Constants'
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import DisplayProgressCircles from "components/DisplayProgressCircles";
import useFetchData from "helpers/useFetchData";
import useUpdateGameData from 'games/helpers/useUpdateGameData';

import "../styles/Games.css";
import ShareItem from 'games/components/ShareItem';


const GamesHistory = ({ profile = null, logIn = () => {}, token = null}) => {
	const url = config.url.API_URL + 'games/history';
	const { getMinuteSecondString } = useUpdateGameData();
	const { data: items, loading, error, errorMessage } = useFetchData({ url, method: 'POST', body: JSON.stringify({ token }) });

	return (
		<div className="games">
			{(loading || error) && (
			<Box sx={{ display: 'grid', placeItems: 'center', height: 'calc(100vh - 350px)'}}>
				{!error && (<DisplayProgressCircles />)}
				{error && (<div className="errorMsg">{errorMessage}</div>)}
			</Box>
			)}
			{(!loading && !items) && (<div className="game">
				<h1>Game Play History</h1>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell>Game</TableCell>
							<TableCell>Date</TableCell>
							<TableCell align="center">Score</TableCell>
							<TableCell align="center">Time</TableCell>
							<TableCell align="center">Shared</TableCell>
							<TableCell align="center">Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell colSpan={6}>
								No Game Play History
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</div>
			)}
			{(!loading && !!items && items.length) && (
			<div className="game">
				<h1>Game Play History</h1>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell>Game</TableCell>
							<TableCell>Date</TableCell>
							<TableCell align="center">Score</TableCell>
							<TableCell align="center">Time</TableCell>
							<TableCell align="center">Shared</TableCell>
							<TableCell align="center">Action</TableCell>
						</TableRow>

					</TableHead>
					<TableBody>
						{items.map((item, index) =>
							<TableRow key={index}>
								<TableCell>
									<Link to={`/game/${item.uri}`} className='gameLink'>
										{item.title}
									</Link>
								</TableCell>
								<TableCell>
									<Link to={`/game/${item.uri}/share/${item.hash}`} className='gameLink'>
										{item.created.substring(0, 19).replace('T', ' ')}
									</Link>
								</TableCell>
								<TableCell align="center">{item.score}</TableCell>
								<TableCell align="center">{getMinuteSecondString(item.time)}</TableCell>
								<TableCell align="center">{item.share}</TableCell>
								<TableCell align="center">
									<ShareItem gameid={item.uri} profile={profile} logIn={logIn} token={token} hash={item.hash} tooltip="Share your Score" />
								</TableCell>
							</TableRow>
						)}
					</TableBody>

				</Table>

				{isMobile && (
				<Box sx={{ width: 'calc(100% - 10px)'}}>
					<Link to="/work" className="button" style={{ float: 'left'}}><span className="symbol prev">&laquo;</span> Work</Link>
					{/* <Link to="/" className="button" style={{ float: 'right'}}>Home <span className="symbol next">&raquo;</span></Link> */}
				</Box>
				)}
			</div>
			)}
		</div>
	);
}

export default GamesHistory;
